<template>
  <PictureBox />
  <ShopCards :scroll="1" />
</template>

<script>
// @ is an alias to /src
import PictureBox from "@/components/PictureBox.vue";
import ShopCards from "@/components/ShopCards.vue";

export default {
  name: "Shop",
  props: {},
  components: {
    PictureBox,
    ShopCards,
  },
};
</script>
