<template>
  <div v-if="!adminloggedin" class="loginContainer">
    <div>
      <h1 class="title">Admin Login</h1>
    </div>
    <div class="inputContainer">
      <input v-model="admin.email" type="text" placeholder="email" />
    </div>
    <div class="inputContainer">
      <input v-model="admin.password" type="password" placeholder="password" />
      <p class="err">{{ loginErrorMsg }}</p>
    </div>
    <div class="btnContainer">
      <button v-on:click="login()" class="loginBtn">Login</button>
    </div>
  </div>
  <AdminHeader v-if="adminloggedin" />
</template>
<script>
import AdminHeader from "@/components/AdminHeader.vue";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "AdminLogin",
  components: {
    AdminHeader,
  },
  props: {},
  data: function () {
    return {
      admin: {
        email: "",
        password: "",
      },
      loginErrorMsg: "",
      adminloggedin: false,
    };
  },
  created: function () {
    this.getLoggedInStatus();
  },
  methods: {
    login: function () {
      this.clearErrorMsg();
      axios
        .post(this.$hostname + "/api/admin/login", this.admin)
        .then((response) => {
          //let validEmail = this.validateEmail(response.data.email);

          let emailvalid = this.validate(response.data.email.valid);
          let passwordvalid = this.validate(response.data.password.valid);

          if (emailvalid && passwordvalid) {
            this.$router.go();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    validate: function (valid) {
      if (!valid) {
        this.loginErrorMsg = "Invalid username or password";
      }
      return valid;
    },
    clearErrorMsg: function () {
      this.loginErrorMsg = "";
    },
    getLoggedInStatus: function () {
      axios
        .get(this.$hostname + "/api/admin/logged-in")
        .then((response) => {
          this.adminloggedin = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.links {
  visibility: hidden;
}
</style>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .loginContainer {
    margin-top: 3%;

    width: 40%;
    margin-left: 30%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    width: 50%;
    margin-left: 25%;
    input {
      height: 25px;
      width: 100%;
    }
  }
  .btnContainer {
    padding: 5%;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .loginContainer {
    margin-top: 3%;

    width: 100%;
    margin-left: 0%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    width: 50%;
    margin-left: 25%;
    input {
      height: 25px;
      width: 100%;
    }
  }
  .btnContainer {
    padding: 5%;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}
</style>
