<template>
  <div class="">
    <div class="">
      <h1 class="title">Add a Price Tier</h1>
      <div class="inputContainer">
        <input v-model="priceTier.tier" type="text" placeholder="Tier Name" />
        <p class="err">{{ tierNameErrorMsg }}</p>
      </div>
      <div class="inputContainer">
        <input
          v-model="priceTier.prices[0]"
          type="text"
          placeholder="1G Price"
        />
        <input
          v-model="priceTier.prices[1]"
          type="text"
          placeholder="3.5G Price"
        />
        <input
          v-model="priceTier.prices[2]"
          type="text"
          placeholder="7G Price"
        />
        <input
          v-model="priceTier.prices[3]"
          type="text"
          placeholder="14G Price"
        />
        <input
          v-model="priceTier.prices[4]"
          type="text"
          placeholder="28G Price"
        />
        <input
          v-model="priceTier.prices[5]"
          type="text"
          placeholder="112G Price"
        />
        <input
          v-model="priceTier.prices[6]"
          type="text"
          placeholder="224G Price"
        />
        <input
          v-model="priceTier.prices[7]"
          type="text"
          placeholder="448G Price"
        />
      </div>
      <div class="btnContainer1">
        <button v-on:click="addPriceTiers()" class="loginBtn">
          Add Price Tiers
        </button>
      </div>
    </div>
    <h1 class="title">Edit Price Tiers</h1>
    <div
      v-for="(p, i) in priceTiers"
      v-bind:key="i"
      class="inputContainer radio"
    >
      <div class="email">{{ p.tier }}</div>
      <div class="priv">
        <div>
          <input v-model="p.prices[0]" type="text" placeholder="1G Price" />
          <input v-model="p.prices[1]" type="text" placeholder="3.5G Price" />
          <input v-model="p.prices[2]" type="text" placeholder="7G Price" />
          <input v-model="p.prices[3]" type="text" placeholder="14G Price" />
          <input v-model="p.prices[4]" type="text" placeholder="28G Price" />
          <input v-model="p.prices[5]" type="text" placeholder="112G Price" />
          <input v-model="p.prices[6]" type="text" placeholder="224G Price" />
          <input v-model="p.prices[7]" type="text" placeholder="448G Price" />
        </div>
      </div>
      <div class="btnContainer">
        <button v-on:click="updatePriceTier(i)" class="loginBtn">Update</button>
        <button v-on:click="deletePriceTier(i)" class="redBtn">
          Delete Tier
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminEditPrivileges",
  props: {},
  data: function () {
    return {
      priceTier: {
        tier: "",
        prices: new Array(8),
      },
      priceTiers: [],

      tierNameErrorMsg: "",
    };
  },
  methods: {
    addPriceTiers: function () {
      axios
        .post(this.$hostname + "/api/admin/price-tier/add", this.priceTier)
        .then((response) => {
          if (response.data == true) {
            this.priceTiers.push(this.priceTier);
          } else {
            if (response.data.tier == false) {
              this.tierNameErrorMsg = "Please enter a valid tier name";
              return;
            }
            this.$router.go();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPriceTiers: function () {
      axios
        .get(this.$hostname + "/api/admin/price-tiers")
        .then((response) => {
          if (response.data) {
            for (var i in response.data) {
              var weights = response.data[i].weights.split(",");
              var pricesParse = response.data[i].prices.split(",");
              var prices = new Array(8);
              for (var j in weights) {
                switch (weights[j]) {
                  case "1":
                    prices[0] = pricesParse[j];
                    break;
                  case "3.5":
                    prices[1] = pricesParse[j];
                    break;
                  case "7":
                    prices[2] = pricesParse[j];
                    break;
                  case "14":
                    prices[3] = pricesParse[j];
                    break;
                  case "28":
                    prices[4] = pricesParse[j];
                    break;
                  case "112":
                    prices[5] = pricesParse[j];
                    break;
                  case "224":
                    prices[6] = pricesParse[j];
                    break;
                  case "448":
                    prices[7] = pricesParse[j];
                    break;
                }
              }
              this.priceTiers.push({
                tier: response.data[i].tier,
                prices: prices,
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updatePriceTier: function (i) {
      axios
        .post(
          this.$hostname + "/api/admin/price-tier/update",
          this.priceTiers[i]
        )
        .then((response) => {
          console.log(response.data);
        });
    },
    deletePriceTier: function (i) {
      axios
        .post(this.$hostname + "/api/admin/price-tier/delete", {
          tier: this.priceTiers[i].tier,
        })
        .then((response) => {
          if (response.data) {
            this.priceTiers.splice(i, 1);
          }
        });
    },
  },
  mounted: function () {
    this.getPriceTiers();
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .loginContainer {
    margin-top: 3%;

    width: 40%;
    margin-left: 30%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: center;
    input {
      height: 25px;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }
  }
  .email {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .priv {
    display: flex;
    flex: 3;
  }
  .radio {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 2%;
    }
    input {
      height: 1.2em;
    }
    label {
      font-size: 14px;
    }
  }
  .btnContainer {
    padding: 5%;
    align-items: center;
    display: flex;
    flex: 1;
  }
  .btnContainer1 {
    padding: 2%;
    align-items: center;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .redBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(
      to right,
      #e52d27 0%,
      #b31217 51%,
      #e52d27 100%
    );
    background-color: #b31217;
    border-radius: 4px;
    border: 1px solid #e52d27;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #e52d27;
  }
  .redBtn:hover {
    background-position: right center;
  }
  .redBtn:active {
    position: relative;
    top: 1px;
  }

  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .loginContainer {
    margin-top: 3%;

    width: 40%;
    margin-left: 30%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: center;
    input {
      height: 2em;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }
  }
  .email {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .priv {
    display: flex;
    flex: 3;
  }
  .radio {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 2%;
    }
    input {
      height: 2em;
    }
    label {
      font-size: 14px;
    }
  }
  .btnContainer {
    padding: 5%;
    align-items: center;
    display: flex;
    flex: 1;
  }
  .btnContainer1 {
    padding: 2%;
    align-items: center;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .redBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(
      to right,
      #e52d27 0%,
      #b31217 51%,
      #e52d27 100%
    );
    background-color: #b31217;
    border-radius: 4px;
    border: 1px solid #e52d27;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #e52d27;
  }
  .redBtn:hover {
    background-position: right center;
  }
  .redBtn:active {
    position: relative;
    top: 1px;
  }

  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}
</style>
