<template>
  <div class="outerShadow">
    <div class="thankYouBox shadow displayNone">
      <div class="checkmarkContainer">
        <h1><i class="fas fa-check"></i></h1>
      </div>
      <div class="orderContainer">
        <h1>Thank you, your order has been placed.</h1>
        <p class="emailConf">
          An email confirmation has been sent to you. We'll send delivery
          updates to your email.<br />
        </p>

        <p class="orderNumber">Order Number: {{ order.referenceId }}</p>
        <ul>
          <li v-for="(product, index) in order.products" :key="index">
            {{ product.name
            }}<span v-if="index == Object.keys(order.products).length - 1"
              >... will be shipped to {{ order.name }} by USCannabis.co</span
            >
          </li>
          <li class="delivery">
            Guaranteed delivery:
            <span
              >{{ getShipMonth() }} {{ deliveryDate.getDate() }},
              {{ deliveryDate.getFullYear() }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="desktopDisplayNone thankYouBox shadow">
      <div class="header">
        <div class="checkmarkContainer">
          <h1><i class="fas fa-check"></i></h1>
        </div>
        <div class="orderContainer">
          <h1>Thank you, your order has been placed.</h1>
        </div>
      </div>
      <div class="orderInfo">
        <p class="emailConf">
          An email confirmation has been sent to you. We'll send delivery
          updates to your email.<br />
        </p>

        <p class="orderNumber">Order Number: {{ order.referenceId }}</p>
        <ul>
          <li v-for="(product, index) in order.products" :key="index">
            {{ product.name
            }}<span v-if="index == Object.keys(order.products).length - 1"
              >... will be shipped to {{ order.name }} by USCannabis.co</span
            >
          </li>
          <li class="delivery">
            Guaranteed delivery:
            <span
              >{{ getShipMonth() }} {{ deliveryDate.getDate() }},
              {{ deliveryDate.getFullYear() }}</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ThankYou",
  props: {
    orderId: String,
  },

  data() {
    return {
      order: {},
      deliveryDate: new Date(),
    };
  },

  mounted: function () {
    this.getOrder();
  },

  methods: {
    getOrder: function () {
      axios
        .post(this.$hostname + "/api/order/get", {
          orderId: this.$props.orderId,
        })
        .then((response) => {
          this.order = response.data;
          this.deliveryDate = new Date(parseInt(this.order.deliveryDate));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getShipMonth: function () {
      const monthNames = [
        "Jan.",
        "Feb.",
        "Mar.",
        "Apr.",
        "May",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dec.",
      ];

      return monthNames[this.deliveryDate.getMonth()];
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
$green: #417e3e;
$shadowgreen: #a7e4a4;
@media (min-width: 768px) {
  .desktopDisplayNone {
    display: none !important;
  }
  .shadow {
    -moz-box-shadow: inset 0 0 2px 3px $shadowgreen;
    -webkit-box-shadow: inset 0 0 2px 3px $shadowgreen;
    box-shadow: inset 0 0 2px 3px $shadowgreen;
  }
  .outerShadow {
    width: 90%;
    margin-left: 5%;
  }
  .thankYouBox {
    display: flex;

    margin-top: 2%;

    border-radius: 2px;
    padding-top: 1%;
    padding-bottom: 3%;

    .checkmarkContainer {
      flex: 1;
      color: $green;
    }
    .orderContainer {
      flex: 9;
      text-align: left;
      h1 {
        color: $green;
        margin-bottom: 0;
      }
      .emailConf {
        margin-top: 1%;
      }
      .orderNumber {
        font-weight: bold;
        margin-bottom: 0;
      }
      ul {
        margin-top: 0.5%;
        li {
          line-height: 1.7em;
        }
      }
      .delivery {
        list-style-type: none;
        font-weight: bold;
        span {
          color: $green;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .displayNone {
    display: none;
  }

  .shadow {
    -moz-box-shadow: inset 0 0 2px 3px $shadowgreen;
    -webkit-box-shadow: inset 0 0 2px 3px $shadowgreen;
    box-shadow: inset 0 0 2px 3px $shadowgreen;
  }
  .outerShadow {
    width: 90%;
    margin-left: 5%;
  }
  .thankYouBox {
    margin-top: 2%;

    border-radius: 2px;
    padding-top: 1%;
    padding-bottom: 3%;
    .header {
      display: flex;
    }
    .checkmarkContainer {
      flex: 1;
      color: $green;
    }
    .orderContainer {
      flex: 4;
      text-align: left;
      h1 {
        color: $green;
        margin-bottom: 0;
        font-size: 1.5em;
      }
    }
    .orderInfo {
      text-align: left;
      padding: 4%;
      .emailConf {
        margin-top: 1%;
      }
      .orderNumber {
        font-weight: bold;
        margin-bottom: 0;
      }
      ul {
        margin-top: 0.5%;
        li {
          line-height: 1.7em;
        }
      }
      .delivery {
        list-style-type: none;
        font-weight: bold;
        span {
          color: $green;
        }
      }
    }
  }
}
</style>
