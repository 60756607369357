<template>
  <div class="picturebox">
    <div class="outdoor">
      <div class="writing-container">
        <transition-group name="fade">
          <h1 v-for="i in [currentIndex]" :key="i" class="writing">
            {{ currentImg }}
          </h1>
        </transition-group>
      </div>
      <img
        class="logo"
        alt="Outdoor Hemp Flower"
        src="../assets/hemp-crop.jpg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PictureBox",
  props: {},

  data() {
    return {
      writings: [
        "Global Hemp Supply Company",
        "Wholesale Prices",
        "Three Day Delivery",
      ],
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function () {
    this.startSlide();
  },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 4000);
    },

    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
  },

  computed: {
    currentImg: function () {
      return this.writings[Math.abs(this.currentIndex) % this.writings.length];
    },
  },
};
</script>

<style lang="scss" scoped>
$green: #417e3e;
@media (min-width: 768px) {
  .outdoor {
    width: 100%;
  }
  img {
    width: 100%;
    height: 600px;
  }
  .writing {
    color: white;
    position: absolute;
    font-size: 96px;
    text-align: center;
    width: 75%;
    text-shadow: 2px 2px #000000;
    margin-left: 12.5%;
    margin-top: 10%;
  }
  .writing-container {
    margin: 0 auto;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.9s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;

    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    visibility: hidden;

    opacity: 0;
  }
}
@media (max-width: 767px) {
  .outdoor {
    width: 100%;
  }
  img {
    width: 100%;
    height: 20%;
  }
  .writing {
    color: white;
    position: absolute;
    font-size: 2.5em;
    text-align: center;
    width: 90%;
    text-shadow: 2px 2px #000000;
    margin-left: 5%;
    margin-top: 10%;
  }
  .writing-container {
    margin: 0 auto;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.9s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;

    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    visibility: hidden;

    opacity: 0;
  }
}
</style>
