<template>
  <PictureBox v-if="!order && !verifyEmail" />
  <ThankYou v-if="order" :orderId="orderId" />
  <EmailVerifyThankYou v-if="verifyEmail" />
  <ShopCards :scroll="0" />
</template>

<script>
// @ is an alias to /src
import PictureBox from "@/components/PictureBox.vue";
import ShopCards from "@/components/ShopCards.vue";
import ThankYou from "@/components/ThankYou.vue";
import EmailVerifyThankYou from "@/components/EmailVerifyThankYou.vue";
import axios from "axios";
import eventBus from "@/eventBus";


export default {
  name: "Home",
  components: {
    PictureBox,
    ShopCards,
    ThankYou,
    EmailVerifyThankYou,
  },
  
  data() {
    return {
      orderId: "",
      order: false,
      verificationCode: "",
      verifyEmail: false,
    };
  },
  mounted: function () {
    this.getOrderId();
    this.getVerificationCode();
    this.showForgotPassword();
  },
  methods: {
    getOrderId: function () {
      //if we have an order then we need to show thank you
      if (this.$route.params.order > 0) {
        this.orderId = this.$route.params.order;
        this.order = true;
      }
      //else we just show the picture box
      else {
        this.order = false;
      }
    },
    getVerificationCode: function () {
      //if we have a valid verification code then we need to show a thank you
      if (this.$route.params.code != null) {
        this.verificationCode = this.$route.params.code;
        //check the database for this code
        axios
          .post(this.$hostname + "/api/user/verify", {
            code: this.verificationCode,
          })
          .then((response) => {
            this.verifyEmail = response.data;
          });
      }
      //else we just show the picture box
      else {
        this.verifyEmail = false;
      }
    },
    showForgotPassword: function () {
      if (this.$route.params.passwordCode != null) {
        //emit password reset event
        eventBus.$emit("passwordReset", this.$route.params.passwordCode);
      }
    },
  },
};
</script>
