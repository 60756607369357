import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

app.directive("visible", function (el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
  el.style.height = binding.value ? "100%" : "0px";
});
app.config.globalProperties.$hostname = "https://uscannabis.co";
app.use(router).mount("#app");



