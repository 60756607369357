<template>
  <div class="container">
    <div class="products-container">
      <div class="head">
        <h1 v-if="cart.length > 0">Shopping Cart</h1>
        <h1 v-if="cart.length == 0">Your Shopping Cart is Empty</h1>
        <p>Price</p>
      </div>
      <div
        v-for="(product, index) in cart"
        v-bind:key="index"
        class="product-container"
      >
        <div
          @click="
            $router.push({
              name: 'Product',
              params: {
                product: product.name.toLowerCase(),
                productId: parseInt(product.id),
              },
            })
          "
          class="image pointer"
        >
          <img v-bind:src="getImgURL(index)" />
        </div>
        <div class="product">
          <h2
            @click="
              $router.push({
                name: 'Product',
                params: {
                  product: product.name.toLowerCase(),
                  productId: parseInt(product.id),
                },
              })
            "
            class="pointer"
          >
            {{ product.name
            }}<span> ({{ getWeight(product.weightGrams) }})</span>
          </h2>
          <div class="rating-stars">
            <a class="selected" href="" data-rating="1"></a
            ><a class="selected" href="" data-rating="2"></a
            ><a class="selected" href="" data-rating="3"></a
            ><a class="selected" href="" data-rating="4"></a
            ><a href="" data-rating="5"></a>
            <span> (654)</span>
          </div>
          <div class="stock">
            <p>In Stock</p>
          </div>
          <div class="shipping"><p>Eligible for FREE Shipping</p></div>

          <div class="options">
            Qty:
            <input
              @change="updateChangeArray(index, $event)"
              type="number"
              id="quantity"
              name="quantity"
              min="1"
              :value="cart[index].quantity"
            />
            <p>|</p>
            <a v-on:click="deleteCartItem(product.id, index)">Delete</a>
          </div>
        </div>
        <div class="price">
          <h3>${{ (product.quantity * product.price).toFixed(2) }}</h3>
        </div>
      </div>
      <div class="subtotal">
        <p>
          Subtotal ({{ getQuantity() }} Items):
          <span>${{ getSubtotal() }}</span>
        </p>
      </div>
    </div>
    <div v-if="cart.length > 0" class="checkout-container">
      <p>
        Subtotal ({{ getQuantity() }} Items): <span>${{ getSubtotal() }}</span>
      </p>
      <div class="btnContainer">
        <button v-on:click="checkout()" class="checkoutBtn">
          Proceed to checkout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import axios from "axios";
export default {
  name: "CartTable",
  props: {},

  data() {
    return {
      cart: [],
      change: {},
    };
  },

  mounted: function () {
    this.getCart();
  },

  methods: {
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach("#card-container");
      return card;
    },
    getCart: function () {
      axios
        .get(this.$hostname + "/api/cart")
        .then((response) => {
          //console.log(response.data)
          this.cart = response.data;
          this.parsePictures();
          //console.log(this.cart);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    parsePictures: function () {
      for (var i in this.cart) {
        this.cart[i].picture = this.cart[i].picture.split(",");
      }
    },
    getQuantity: function () {
      var quantity = 0;
      for (var i in this.cart) {
        quantity += parseInt(this.cart[i].quantity);
      }
      return quantity;
    },
    getSubtotal: function () {
      var subtotal = 0;
      for (var i in this.cart) {
        subtotal += this.cart[i].quantity * this.cart[i].price;
      }
      return subtotal.toFixed(2);
    },
    getWeight: function (weight) {
      switch (weight) {
        case 1:
          return "1g";
        case 3.5:
          return "3.5g";
        case 7:
          return "7g";
        case 14:
          return "14g";
        case 28:
          return "1oz";
        case 112:
          return "4oz";
        case 224:
          return "8oz";
        case 448:
          return "1 pound";
      }
    },
    getImgURL: function (index) {
      return this.$hostname + "/uploads/" + this.cart[index].picture[0];
    },
    deleteCartItem: function (id, index) {
      axios
        .post(this.$hostname + "/api/cart/delete", { id: id })
        .then((response) => {
          if (response.data == true) {
            eventBus.$emit("atc", this.cart[index].quantity * -1);
            this.cart.splice(index, 1);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateChangeArray: function (index, event) {
      var qty = event.target.value - this.cart[index].quantity;
      this.cart[index].quantity = event.target.value;
      eventBus.$emit("atc", qty);
      this.change[this.cart[index].id] = this.cart[index].quantity;
    },
    checkout: function () {
      //updatechange array
      axios
        .post(this.$hostname + "/api/cart/update", this.change)
        .then(() => {
          this.$router.push({ path: "/checkout" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateCart: function (next) {
      axios
        .post(this.$hostname + "/api/cart/update", this.change)
        .then(() => {
          next();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
$green: #417e3e;
.pointer {
  cursor: pointer;
}
@media (min-width: 768px) {
  .container {
    padding: 2% 10%;
    display: flex;
  }
  .products-container {
    padding-right: 5%;
    flex: 8;
  }
  .checkout-container {
    padding-left: 5%;
    text-align: left;
    flex: 2;
    p {
      font-size: 1.17em;
      span {
        font-weight: bold;
      }
    }
    .btnContainer {
      display: flex;
      justify-content: center;
      padding-top: 5%;
    }
  }
  .head {
    color: $green;
    height: auto;
    border-bottom: 1px solid #eeeeee;
    h1 {
      text-align: left;
      margin: 0;
    }
    p {
      text-align: right;
      margin: 0;
    }
  }
  .product-container {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 2%;
    display: flex;
    .image {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      img {
        width: 70%;
      }
    }
    .product {
      flex: 9;
      text-align: left;
      h2 {
        color: $green;
        margin-bottom: 0.5%;
        span {
          font-size: 12px;
          color: #2c3e50;
          font-weight: normal;
        }
      }
      .checked {
        color: orange;
      }

      .stock {
        p {
          color: $green;
          font-size: 12px;
          margin-top: 0.5%;
          margin-bottom: 0.5%;
        }
      }
      .shipping {
        p {
          font-size: 10px;
          margin-top: 1%;
          color: #565959;
        }
      }
      .options {
        padding-top: 2%;
        display: flex;
        align-items: center;
        color: #2c3e50;
        font-size: 14px;
        input {
          margin-left: 1%;
          width: 6%;
          font-size: 14px;
        }
        p {
          margin: 0;
          margin-left: 2%;
          margin-right: 2%;
          font-size: 16px;
        }
        a {
          font-size: 12px;
          text-decoration: none;
          cursor: pointer;
          color: $green;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .price {
      flex: 1;
      text-align: right;
    }
  }
  .subtotal {
    text-align: right;
    font-size: 1.17em;
    span {
      font-weight: bold;
    }
  }
  .checkoutBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
    width: 100%;
  }
  .checkoutBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .checkoutBtn:active {
    position: relative;
    top: 1px;
  }

  .rating-stars span {
    font-size: 12px;
    vertical-align: text-top;
  }
  .rating-stars a {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgNjQgNjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDY0IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSJub25lIiBzdHJva2U9IiNBN0E5QUMiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjMxLjg2Niw2LjYxOCA0MC4wOSwyMy4yODEgNTguNDc5LDI1Ljk1MyA0NS4xNzIsMzguOTIzIDQ4LjMxMyw1Ny4yMzkgMzEuODY2LDQ4LjU5MiAxNS40MTgsNTcuMjM5IDE4LjU2LDM4LjkyMyA1LjI1MywyNS45NTMgMjMuNjQyLDIzLjI4MSAiLz48L3N2Zz4=");
    outline: none;
  }
  .rating-stars a.hover {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiCiAgIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIGZpbGw9IiM1MUFFQ0QiIHN0cm9rZT0iIzUxQUVDRCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iMzEuODY2LDYuNjE4IDQwLjA5LDIzLjI4MSA1OC40NzksMjUuOTUzIDQ1LjE3MiwzOC45MjMgNDguMzEzLDU3LjIzOSAzMS44NjYsNDguNTkyIDE1LjQxOCw1Ny4yMzkgMTguNTYsMzguOTIzIDUuMjUzLDI1Ljk1MyAyMy42NDIsMjMuMjgxICIvPjwvc3ZnPgo=");
  }
  .rating-stars a.selected {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgNjQgNjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDY0IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkVDMjBGIiBzdHJva2U9IiNGRUMyMEYiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjMxLjg2Niw2LjYxOCA0MC4wOSwyMy4yODEgNTguNDc5LDI1Ljk1MyA0NS4xNzIsMzguOTIzIDQ4LjMxMyw1Ny4yMzkgMzEuODY2LDQ4LjU5MiAxNS40MTgsNTcuMjM5IDE4LjU2LDM4LjkyMyA1LjI1MywyNS45NTMgMjMuNjQyLDIzLjI4MSAiLz48L3N2Zz4=");
  }
}

@media (max-width: 767px) {
  .container {
    padding: 2% 10%;
  }
  .products-container {
    padding-right: 5%;
  }
  .checkout-container {
    padding-left: 5%;
    text-align: left;
    padding-bottom: 20%;
    p {
      font-size: 1.17em;
      span {
        font-weight: bold;
      }
    }
    .btnContainer {
      display: flex;
      justify-content: center;
      padding-top: 5%;
    }
  }
  .head {
    color: $green;
    height: auto;
    border-bottom: 1px solid #eeeeee;
    h1 {
      text-align: left;
      margin: 0;
    }
    p {
      text-align: right;
      margin: 0;
    }
  }
  .product-container {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 2%;
    display: flex;
    .image {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      img {
        width: 70%;
      }
    }
    .product {
      flex: 9;
      text-align: left;
      h2 {
        color: $green;
        margin-bottom: 0.5%;
        span {
          font-size: 12px;
          color: #2c3e50;
          font-weight: normal;
        }
      }
      .checked {
        color: orange;
      }

      .stock {
        p {
          color: $green;
          font-size: 12px;
          margin-top: 0.5%;
          margin-bottom: 0.5%;
        }
      }
      .shipping {
        p {
          font-size: 10px;
          margin-top: 1%;
          color: #565959;
        }
      }
      .options {
        padding-top: 2%;
        display: flex;
        align-items: center;
        color: #2c3e50;
        font-size: 14px;
        input {
          margin-left: 1%;
          width: 12%;
          font-size: 14px;
        }
        p {
          margin: 0;
          margin-left: 2%;
          margin-right: 2%;
          font-size: 16px;
        }
        a {
          font-size: 12px;
          text-decoration: none;
          cursor: pointer;
          color: $green;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .price {
      flex: 1;
      text-align: right;
    }
  }
  .subtotal {
    display: none;
    text-align: right;
    font-size: 1.17em;
    span {
      font-weight: bold;
    }
  }
  .checkoutBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
    width: 100%;
  }
  .checkoutBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .checkoutBtn:active {
    position: relative;
    top: 1px;
  }

  .rating-stars span {
    font-size: 12px;
    vertical-align: text-top;
  }
  .rating-stars a {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgNjQgNjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDY0IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSJub25lIiBzdHJva2U9IiNBN0E5QUMiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjMxLjg2Niw2LjYxOCA0MC4wOSwyMy4yODEgNTguNDc5LDI1Ljk1MyA0NS4xNzIsMzguOTIzIDQ4LjMxMyw1Ny4yMzkgMzEuODY2LDQ4LjU5MiAxNS40MTgsNTcuMjM5IDE4LjU2LDM4LjkyMyA1LjI1MywyNS45NTMgMjMuNjQyLDIzLjI4MSAiLz48L3N2Zz4=");
    outline: none;
  }
  .rating-stars a.hover {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiCiAgIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIGZpbGw9IiM1MUFFQ0QiIHN0cm9rZT0iIzUxQUVDRCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iMzEuODY2LDYuNjE4IDQwLjA5LDIzLjI4MSA1OC40NzksMjUuOTUzIDQ1LjE3MiwzOC45MjMgNDguMzEzLDU3LjIzOSAzMS44NjYsNDguNTkyIDE1LjQxOCw1Ny4yMzkgMTguNTYsMzguOTIzIDUuMjUzLDI1Ljk1MyAyMy42NDIsMjMuMjgxICIvPjwvc3ZnPgo=");
  }
  .rating-stars a.selected {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgNjQgNjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDY0IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkVDMjBGIiBzdHJva2U9IiNGRUMyMEYiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjMxLjg2Niw2LjYxOCA0MC4wOSwyMy4yODEgNTguNDc5LDI1Ljk1MyA0NS4xNzIsMzguOTIzIDQ4LjMxMyw1Ny4yMzkgMzEuODY2LDQ4LjU5MiAxNS40MTgsNTcuMjM5IDE4LjU2LDM4LjkyMyA1LjI1MywyNS45NTMgMjMuNjQyLDIzLjI4MSAiLz48L3N2Zz4=");
  }
}
</style>
