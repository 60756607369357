<template>
  <div class="outerShadow">
    <div class="thankYouBox shadow displayNone">
      <div class="checkmarkContainer">
        <h1><i class="fas fa-check"></i></h1>
      </div>
      <div class="orderContainer">
        <h1>Thank you, for verifying your email.</h1>
        <p class="emailConf">
          Your shipping, billing, and payment information can now be saved for
          future purchases.<br />
        </p>

        <p class="orderNumber">
          Please login to your account using the sign in button above.
        </p>
      </div>
    </div>
    <div class="desktopDisplayNone thankYouBox shadow">
      <div class="header">
        <div class="checkmarkContainer">
          <h1><i class="fas fa-check"></i></h1>
        </div>
        <div class="orderContainer">
          <h1>Thank you, for verifying your email.</h1>
        </div>
      </div>
      <div class="orderInfo">
        <p class="emailConf">
          Your shipping, billing, and payment information can now be saved for
          future purchases.<br />
        </p>

        <p class="orderNumber">
          Please login to your account using the sign in button located inside
          of the dropdown menu above.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailVerifyThankYou",
  props: {},

  data() {
    return {};
  },

  mounted: function () {},

  methods: {},

  computed: {},
};
</script>

<style lang="scss" scoped>
$green: #417e3e;
$shadowgreen: #a7e4a4;
@media (min-width: 768px) {
  .desktopDisplayNone {
    display: none !important;
  }
  .shadow {
    -moz-box-shadow: inset 0 0 2px 3px $shadowgreen;
    -webkit-box-shadow: inset 0 0 2px 3px $shadowgreen;
    box-shadow: inset 0 0 2px 3px $shadowgreen;
  }
  .outerShadow {
    width: 90%;
    margin-left: 5%;
  }
  .thankYouBox {
    display: flex;

    margin-top: 2%;

    border-radius: 2px;
    padding-top: 1%;
    padding-bottom: 3%;

    .checkmarkContainer {
      flex: 1;
      color: $green;
    }
    .orderContainer {
      flex: 9;
      text-align: left;
      h1 {
        color: $green;
        margin-bottom: 0;
      }
      .emailConf {
        margin-top: 1%;
      }
      .orderNumber {
        font-weight: bold;
        margin-bottom: 0;
      }
      ul {
        margin-top: 0.5%;
        li {
          line-height: 1.7em;
        }
      }
      .delivery {
        list-style-type: none;
        font-weight: bold;
        span {
          color: $green;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .displayNone {
    display: none;
  }

  .shadow {
    -moz-box-shadow: inset 0 0 2px 3px $shadowgreen;
    -webkit-box-shadow: inset 0 0 2px 3px $shadowgreen;
    box-shadow: inset 0 0 2px 3px $shadowgreen;
  }
  .outerShadow {
    width: 90%;
    margin-left: 5%;
  }
  .thankYouBox {
    margin-top: 2%;

    border-radius: 2px;
    padding-top: 1%;
    padding-bottom: 3%;
    .header {
      display: flex;
    }
    .checkmarkContainer {
      flex: 1;
      color: $green;
    }
    .orderContainer {
      flex: 4;
      text-align: left;
      h1 {
        color: $green;
        margin-bottom: 0;
        font-size: 1.5em;
      }
    }
    .orderInfo {
      text-align: left;
      padding: 4%;
      .emailConf {
        margin-top: 1%;
      }
      .orderNumber {
        font-weight: bold;
        margin-bottom: 0;
      }
      ul {
        margin-top: 0.5%;
        li {
          line-height: 1.7em;
        }
      }
      .delivery {
        list-style-type: none;
        font-weight: bold;
        span {
          color: $green;
        }
      }
    }
  }
}
</style>
