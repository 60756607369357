<template>
  <CartTable ref="carttable" />
</template>

<script>
// @ is an alias to /src
import CartTable from "@/components/CartTable.vue";

export default {
  name: "Cart",
  components: {
    CartTable,
  },
  beforeRouteLeave: function (to, from, next) {
    // Indicate to the SubComponent that we are leaving the route
    this.$refs.carttable.updateCart(next);
    // Make sure to always call the next function, otherwise the hook will never be resolved
    // Ref: https://router.vuejs.org/en/advanced/navigation-guards.html
  },
};
</script>
