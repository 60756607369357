<template>
  <div class="header">
    <div @click="menu = !menu" class="hamburger">
      <img class="menu" alt="Cart" src="../assets/hamburger.svg" />
    </div>
    <div class="logo-container">
      <router-link to="/"
        ><img
          class="logo"
          alt="USCannabis logo"
          src="../assets/uscannabisco.png"
      /></router-link>
    </div>
    <div
      v-on:click="$router.push('/cart')"
      class="cartContainer desktopDisplayNone"
    >
      <router-link to="/cart"
        ><img class="cart" alt="Cart" src="../assets/cart.svg"
      /></router-link>
      <p>{{ count }}</p>
    </div>
    <div class="links">
      <router-link :to="{ name: 'Shop' }">Shop <i class="fas fa-caret-down" style="font-size: 0.9em;"></i></router-link>
      <a v-show="!loggedin" @click="close = !close" href="#">Sign In</a>
      <a v-show="loggedin" v-on:click="logout()" href="#">Logout</a>
      <router-link to="/cart"
        ><i class="fas fa-shopping-basket"></i> {{ count }} items</router-link
      >


<div style="
    flex-basis: 100%;
    height: 0;
"></div>
      <div v-show="shopMenu" class="exploreOverlay" style="
    background-color: rgba(0,0,0,.2);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
"></div>
    <div v-show="shopMenu" class="shopMenu" style="
      display: flex;
      flex: .663;">
      <div style="
    width: 0;
    height: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid #fff;
    
    /* margin-top: 2px; */
    position: relative;
    z-index: 2;
">
    <div style="
    width: 30em;
    /* height: auto; */
    background-color: #fff;
    margin-top: 0.5em;
    margin-left: -9em;
    /* border: 1px solid #417e3e; */
    z-index: 190;
    position: absolute;
    border-radius: 5px;
    padding: 20px;
"><h3 style="
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
">Explore</h3><p class="subheading" style="
    font-variant: small-caps;
    font-weight: 700;
    color: rgba(56,99,38,.75);
    text-align: left;
    margin-top: 0;
    margin-bottom: 0.5em;
">our departments</p><div style="
    border-top: 1px solid #eeeeee;
"></div>
<p style="
    text-align: left;
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
    padding: 2%;
">CBD</p>
<p style="
    text-align: left;
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
    padding: 2%;
">Delta-8</p>
<p style="
    text-align: left;
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
    padding: 2%;
">HHC</p>
<p style="
    text-align: left;
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
    padding: 2%;
">THC-O</p></div>
</div>
</div>
    </div>
  </div>


  <div v-show="menu" class="dark-overlay1">
    <div class="menuContainer">
      <div class="logo-container">
        <router-link to="/"
          ><img
            class="logo"
            alt="USCannabis logo"
            src="../assets/uscannabisco.png"
        /></router-link>
      </div>
      <div class="menu">
        <div class="signin">
          <h3
            v-show="!loggedin"
            @click="
              close = !close;
              menu = false;
            "
          >
            Sign In
          </h3>
          <h3 v-show="loggedin" v-on:click="logout()">Sign Out</h3>
        </div>
        <div class="explore">
          <router-link @click="menu = false" :to="{ name: 'Shop' }"
            ><h3>Explore</h3></router-link
          >
          <p class="subheading">top departments</p>
          <router-link
            @click="menu = false"
            :to="{ name: 'Subcategory', params: { subcategory: 'CBD' } }"
            ><p class="underlings">CBD</p></router-link
          >
          <router-link
            @click="menu = false"
            :to="{ name: 'Subcategory', params: { subcategory: 'Delta-8' } }"
            ><p class="underlings">Delta-8</p></router-link
          >
          <router-link
            @click="menu = false"
            :to="{ name: 'Subcategory', params: { subcategory: 'THC-O' } }"
            ><p class="underlings">THC-O</p></router-link
          >
        </div>
        <div class="promotions">
          <p class="subheading">top promotions for you</p>
          <router-link @click="menu = false" :to="{ name: 'Shop' }"
            ><p class="underlings">Holiday Deals</p></router-link
          >
          <router-link @click="menu = false" :to="{ name: 'Shop' }"
            ><p class="underlings">Best Sellers</p></router-link
          >
          <router-link @click="menu = false" :to="{ name: 'Shop' }"
            ><p class="underlings">Deals & Closeouts</p></router-link
          >
        </div>
      </div>
    </div>
    <div class="closeContainer">
      <img
        v-on:click="toggleMenuClose()"
        class=""
        alt="Close"
        src="../assets/close.svg"
      />
    </div>
  </div>

  <div v-show="close" class="dark-overlay">
    <div class="login-container">
      <div>
        <a v-on:click="toggleClose()" href="#"
          ><img class="close" alt="Close" src="../assets/close-green.svg"
        /></a>
      </div>

      <div class="input-container">
        <div class="logo-container">
          <img
            class="logo"
            alt="USCannabis logo"
            src="../assets/uscannabisco.png"
          />
        </div>

        <div v-show="login">
          <div>
            <p>Email</p>
            <input
              v-on:keyup.enter="loginUser()"
              v-model="user.email"
              type="email"
            />
            <p class="err">{{ emailError }}</p>
          </div>
          <div>
            <p>Password</p>
            <input
              v-on:keyup.enter="loginUser()"
              v-model="user.password"
              type="password"
            />
            <p class="err">{{ passwordError }}</p>
            <p v-on:click="toggleForgotPassword()" class="forgot">
              Forgot Password?
            </p>
          </div>
          <div class="btnContainer">
            <button
              v-on:click="loginUser()"
              v-on:keyup.enter="loginUser()"
              class="loginBtn"
            >
              Login
            </button>
          </div>
          <p v-on:click="toggleCreateAccount()" class="register">
            Create an Account
          </p>
        </div>

        <div v-show="createAccount">
          <div>
            <p>Email</p>
            <input
              v-on:keyup.enter="createUser()"
              v-model="user.email"
              type="email"
            />
            <p class="err">{{ emailError }}</p>
          </div>
          <div>
            <p>Password</p>
            <input
              v-on:keyup.enter="createUser()"
              v-model="user.password"
              type="password"
            />
            <p class="err">{{ passwordError }}</p>
          </div>
          <div>
            <p>Confirm Password</p>
            <input
              v-on:keyup.enter="createUser()"
              v-model="user.confirmPassword"
              type="password"
            />
            <p class="err">{{ confirmPasswordError }}</p>
          </div>
          <div class="btnContainer">
            <button
              v-on:click="createUser()"
              v-on:keyup.enter="createUser()"
              class="loginBtn"
            >
              Create Account
            </button>
          </div>
          <p v-on:click="toggleLogin()" class="register">Login</p>
        </div>

        <div v-show="forgotPassword">
          <div v-if="!passwordResetMenu">
            <p>Email</p>
            <input
              v-on:keyup.enter="emailForgotPassword()"
              v-model="user.email"
              type="email"
            />
            <p class="err">{{ emailError }}</p>
          </div>
          <div v-if="passwordResetMenu">
            <p>New Password</p>
            <input
              v-on:keyup.enter="changePassword()"
              v-model="user.password"
              type="password"
            />
            <p class="err">{{ passwordError }}</p>
          </div>
          <div v-if="passwordResetMenu">
            <p>Confirm New Password</p>
            <input
              v-on:keyup.enter="changePassword()"
              v-model="user.confirmPassword"
              type="password"
            />
            <p class="err">{{ confirmPasswordError }}</p>
          </div>

          <div class="btnContainer">
            <button
              v-if="!passwordResetMenu"
              v-on:click="emailForgotPassword()"
              v-on:keyup.enter="emailForgotPassword()"
              class="loginBtn"
            >
              Continue
            </button>
            <button
              v-if="passwordResetMenu"
              v-on:click="changePassword()"
              v-on:keyup.enter="changePassword()"
              class="loginBtn"
            >
              Continue
            </button>
          </div>
          <p v-on:click="toggleLogin()" class="register">Login</p>
          <p v-on:click="toggleCreateAccount()" class="register">
            Create an Account
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "Header",
  props: {},
  data() {
    return {
      count: 0,
      close: false,
      menu: false,
      loggedin: false,
      login: true,
      createAccount: false,
      forgotPassword: false,
      passwordResetMenu: false,
      user: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      emailErrors: {
        empty: "Please enter a valid email address",
        valid: "Please enter a valid email address",
        exists: "This email address already exists",
        notexists: "This email address does not exist",
      },
      emailError: "",
      passwordErrors: {
        empty: "Password must be greater than 8 characters",
        length: "Password must be greater than 8 characters",
        matches: "You have entered an invalid username or password",
      },
      passwordError: "",
      confirmPasswordErrors: {
        matches: "Password doesn't match",
      },
      confirmPasswordError: "",
      passwordResetCode: "",
      shopMenu: false,
    };
  },
  beforeMount: function () {
    this.getLoggedInStatus();
    this.getCartSum();
  },
  mounted: function () {
    eventBus.$on("atc", this.updateCart);
    eventBus.$on("passwordReset", this.showPasswordReset);
    this.setTempUserCookie();
  },
  methods: {
    updateCart: function (data) {
      this.count = parseInt(data) + parseInt(this.count);
    },
    getCartSum: function () {
      axios
        .get(this.$hostname + "/api/cart/sum")
        .then((response) => {
          this.count = response.data.qty;
          if (this.count == null) this.count = 0;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showPasswordReset: function (data) {
      this.passwordResetCode = data;
      this.toggleClose();
      this.toggleForgotPassword();
      this.passwordResetMenu = true;
    },
    toggleClose: function () {
      this.close = !this.close;
      this.login = true;
      this.forgotPassword = false;
      this.createAccount = false;
      this.clearErrorMsg();
    },
    toggleMenuClose: function () {
      this.menu = false;
    },
    toggleCreateAccount: function () {
      this.toggleMenuClose();
      this.login = false;
      this.forgotPassword = false;
      this.createAccount = true;
      this.clearErrorMsg();
    },
    toggleLogin: function () {
      this.toggleMenuClose();
      this.createAccount = false;
      this.forgotPassword = false;
      this.login = true;
      this.clearErrorMsg();
    },
    toggleForgotPassword: function () {
      this.toggleMenuClose();
      this.createAccount = false;
      this.login = false;
      this.forgotPassword = true;
      this.clearErrorMsg();
    },
    createUser: function () {
      this.clearErrorMsg();
      axios
        .post(this.$hostname + "/api/user/create", this.user)
        .then((response) => {
          this.validateEmail(response.data.email);
          this.validatePassword(response.data.password);
          this.validateConfirmPassword(response.data.confirmPassword);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loginUser: function () {
      this.clearErrorMsg();
      axios
        .post(this.$hostname + "/api/user/login", this.user)
        .then((response) => {
          let validEmail = this.validateEmail(response.data.email);
          let validPassword = this.validatePassword(response.data.password);
          if (validEmail && validPassword) {
            //need to fix add all items to user's cart
            axios
              .get(this.$hostname + "/api/cart/loginupdate")
              .then(() => {})
              .catch((e) => {
                this.errors.push(e);
              });
            this.$router.go();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    logout: function () {
      axios
        .get(this.$hostname + "/api/user/logout")
        .then((response) => {
          this.loggedin = response.data;
          this.$router.go();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    emailForgotPassword: function () {
      this.clearErrorMsg();
      axios
        .post(this.$hostname + "/api/user/forgot-password", this.user)
        .then((response) => {
          this.validateEmail(response.data.email);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    changePassword: function () {
      this.clearErrorMsg();
      this.user.passwordResetCode = this.passwordResetCode;
      axios
        .post(this.$hostname + "/api/user/change-password", this.user)
        .then((response) => {
          this.validatePassword(response.data.password);
          this.validateConfirmPassword(response.data.confirmPassword);
          if (response.data == false) {
            //invalid password reset link
            this.passwordError = "Invalid password reset link";
          } else {
            this.$router.push("/");
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getLoggedInStatus: function () {
      axios
        .get(this.$hostname + "/api/user/logged-in")
        .then((response) => {
          this.loggedin = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    validateEmail: function (data) {
      this.emailError = "";
      for (const property in data) {
        if (!data[property]) {
          this.emailError = this.emailErrors[property];
          return false;
        }
      }
      return true;
    },
    validatePassword: function (data) {
      this.passwordError = "";
      for (const property in data) {
        if (!data[property]) {
          this.passwordError = this.passwordErrors[property];
          return false;
        }
      }
      return true;
    },
    validateConfirmPassword: function (data) {
      this.confirmPasswordError = "";
      for (const property in data) {
        if (!data[property]) {
          this.confirmPasswordError = this.confirmPasswordErrors[property];
          return;
        }
      }
    },
    clearErrorMsg: function () {
      this.emailError = "";
      this.passwordError = "";
      this.confirmPasswordError = "";
    },

    setTempUserCookie: function () {
      axios.get(this.$hostname + "/api/user/guest");
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .hamburger {
    display: none;
  }
  .desktopDisplayNone {
    display: none;
  }
  .header {
    border-bottom: 1px solid #efefef;
    display: flex;

    align-items: center;
    font-weight: 500;
    color: #417e3e;
    .logo-container {
      flex: 1;
      align-content: flex-start;
      text-align: left;
    }
    .logo {
      width: 450px;
      margin-left: 10%;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
    .links {
      display: flex;
      justify-content: space-around;
      flex: 1.7;
      flex-wrap: wrap;
    }
    .cart {
      width: 20px;
    }
    a {
      text-decoration: none;
      color: #417e3e;
    }
  }
  .dark-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    z-index: 1;
    top: 0;

    .close {
      float: right;
      margin-right: 2%;
      margin-top: 1%;
      color: #346431;
    }
    .login-container {
      position: fixed;
      width: 40%;
      left: 50%;
      top: 50%;
      height: 50%;
      background-color: white;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
    .logo {
      width: 80%;
      padding-bottom: 0%;
    }
    .input-container {
      padding: 10%;
      padding-top: 5%;
      width: inherit;
      margin-left: 20%;
      .err {
        color: #ff0033;
        font-size: 10px;
        font-weight: 400;
      }
      .register {
        font-weight: bold;
        font-size: 12px;
        margin-top: 5px;
        text-align: center;
      }
      .forgot {
        font-weight: bold;
        font-size: 12px;
        margin-top: 5px;
        text-align: left;
        cursor: pointer;
      }
      div {
        padding-top: 5%;
      }
      p {
        text-align: left;
        margin: 0;
        width: 100%;
        font-weight: bold;
        color: #346431;
        cursor: pointer;
      }
      input {
        width: 100%;
      }

      .btnContainer {
        padding: 5%;
        padding-top: 15%;
      }
      .loginBtn {
        box-shadow: 0px 10px 14px -7px #3e7327;
        background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
        background-color: #417e3e;
        border-radius: 4px;
        border: 1px solid #4b8f29;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        padding: 10px 28px;
        text-decoration: none;
        text-shadow: 0px 1px 0px #5b8a3c;
      }
      .loginBtn:hover {
        background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
        background-color: #346431;
      }
      .loginBtn:active {
        position: relative;
        top: 1px;
      }
    }
  }
}
@media (max-width: 767px) {
  .header {
    display: flex;
    align-items: center;
    .logo-container {
      flex: 5;
      img {
        width: 100%;
      }
    }
    .links {
      display: none;
    }
    .hamburger {
      flex: 1;
      display: block;
      color: #417e3e;
      padding: 8%;
    }
    .cartContainer {
      flex: 1;
      padding: 9%;
      position: relative;
      p {
        color: #386326;
        font-weight: bold;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 4px;
        right: 0;
        margin-top: 29%;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
      }
    }
  }
  .dark-overlay1 {
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    z-index: 1;
    top: 0;
    display: flex;
    .menuContainer {
      flex: 4;
      height: 100%;
      background-color: #fff;
      overflow-y: scroll;
      .logo-container {
        border-bottom: 1px solid #eeeeee;
        img {
          width: 70%;
        }
      }
      .menu {
        color: #417e3e;
        text-align: left;
        .signin {
          padding: 0 5%;
          border-bottom: 1px solid #eeeeee;
          cursor: pointer;
        }
        .explore {
          border-bottom: 1px solid #eeeeee;
          padding: 0 5%;
          a {
            text-decoration: none;
          }
          h3 {
            color: #417e3e;
            text-decoration: none;
          }
        }
        .promotions {
          a {
            text-decoration: none;
          }
          padding: 0 5%;
        }
        .subheading {
          font-variant: small-caps;
          font-weight: bold;
          color: rgba(56, 99, 38, 0.75);
        }
        .underlings {
          font-weight: 300;
          padding: 1%;
          color: #417e3e;
          text-decoration: none;
        }
      }
    }
    .closeContainer {
      flex: 1;
      color: #fff;
      img {
        padding-top: 50%;
        cursor: pointer;
      }
    }
  }
  .dark-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    z-index: 1;
    top: 0;
    .close {
      float: right;
      margin-right: 2%;
      margin-top: 2%;
      color: #346431;
      font-size: 1.75em;
    }
    .login-container {
      position: fixed;
      width: 100%;
      left: 50%;
      top: 50%;
      height: auto;
      background-color: white;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
    .logo {
      width: 80%;
      padding-bottom: 0%;
    }
    .input-container {
      padding: 10%;
      padding-top: 5%;
      width: 80%;
      margin-left: 0;
      .err {
        color: #ff0033;
        font-size: 10px;
        font-weight: 400;
      }
      .register {
        font-weight: bold;
        font-size: 0.75em;
        margin-top: 5px;
        text-align: center;
      }
      .forgot {
        font-weight: bold;
        font-size: 0.75em;
        margin-top: 5px;
        text-align: left;
        cursor: pointer;
      }
      div {
        padding-top: 3%;
      }
      p {
        text-align: left;
        margin: 0;
        width: 100%;
        font-size: 1.05em;
        font-weight: normal;
        color: #346431;
        cursor: pointer;
      }
      input {
        width: 100%;
        line-height: 2em;
      }
      .btnContainer {
        padding: 5%;
        padding-top: 10%;
      }
      .loginBtn {
        box-shadow: 0px 10px 14px -7px #3e7327;
        background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
        background-color: #417e3e;
        border-radius: 4px;
        border: 1px solid #4b8f29;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        padding: 10px 28px;
        text-decoration: none;
        text-shadow: 0px 1px 0px #5b8a3c;
      }
      .loginBtn:hover {
        background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
        background-color: #346431;
      }
      .loginBtn:active {
        position: relative;
        top: 1px;
      }
    }
  }
}
</style>
