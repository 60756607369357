import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Cart from "../views/Cart.vue";
import Admin from "../views/Admin.vue";
import Shop from "../views/Shop.vue";
import Subcategory from "../views/Subcategory.vue";
import Product from "../views/Product.vue";
import Checkout from "../views/Checkout.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: 'US Cannabis carries a small selection of high quality, fresh hemp flower infused with HHC Oil. All of our HHC flower is Farm Bill compliant and contains less than 0.3% THC.'
        },
        {
          property: 'og:description',
          content: 'US Cannabis carries a small selection of high quality, fresh hemp flower infused with HHC Oil. All of our HHC flower is Farm Bill compliant and contains less than 0.3% THC.'
        }
      ],
    },
  },
  {
    path: "/verify/:code",
    name: "Email Verification",
    component: Home,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ],
    },
  },
  {
    path: "/password-reset/:passwordCode",
    name: "Password Reset",
    component: Home,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ],
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ],
    },
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
    props: true,
    meta: {
      title: "Wholesale HHC Flower | Buy Bulk HHC Flower by the pound",
      metaTags: [
        {
          name: 'description',
          content: 'US Cannabis carries a small selection of high quality, fresh hemp flower infused with HHC Oil. All of our HHC flower is Farm Bill compliant and contains less than 0.3% THC.'
        },
        {
          property: 'og:description',
          content: 'US Cannabis carries a small selection of high quality, fresh hemp flower infused with HHC Oil. All of our HHC flower is Farm Bill compliant and contains less than 0.3% THC.'
        }
      ],
    },
  },
  {
    path: "/:subcategory",
    name: "Subcategory",
    component: Subcategory,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ],
    },
  },
  {
    path: "/products/:product",
    name: "Product",
    component: Product,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ],
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ],
    },
  },

  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: "US Cannabis | Lowest Priced Wholesale Hemp Products",
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
