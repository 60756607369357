<template>
  <div class="">
    <div
      v-for="(admin1, i) in admins"
      v-bind:key="i"
      class="inputContainer radio"
    >
      <div class="email">{{ admin1.username }}</div>
      <div class="priv">
        <div v-for="(link, index) in links" v-bind:key="index">
          <input
            v-model="admin1[camelCase(link)]"
            type="checkbox"
            :id="index"
            :name="index"
            :value="link"
          />
          <label :for="index">{{ link }}</label>
        </div>
      </div>
      <div class="btnContainer">
        <button v-on:click="updateAdmin(i)" class="loginBtn">Update</button>
        <button v-on:click="deleteAdmin(admin1.id, i)" class="redBtn">
          Delete User
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminEditPrivileges",
  props: {},
  data: function () {
    return {
      links: [],
      admins: [],
      err: {
        email: {
          valid: "Please enter a valid email address",
          exists: "This email address already exists",
        },
        password: {
          length: "Password must be greater than 8 characters",
        },
        confirmPassword: {
          matches: "Password doesn't match",
        },
      },
      emailErrorMsg: "",
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
    };
  },
  methods: {
    getLinks: function () {
      axios
        .get(this.$hostname + "/api/admin/links")
        .then((response) => {
          this.links = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAdmins: function () {
      axios
        .get(this.$hostname + "/api/admin/admin-list")
        .then((response) => {
          this.admins = response.data;
          for (const i in this.admins) {
            for (const property in this.admins[i]) {
              if (this.admins[i][property] == 1) {
                this.admins[i][property] = true;
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteAdmin: function (id, arrayIndex) {
      axios
        .post(this.$hostname + "/api/admin/delete", { id: id })
        .then((response) => {
          if (response.data) {
            this.admins.splice(arrayIndex, 1);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateAdmin: function (arrayIndex) {
      axios
        .post(this.$hostname + "/api/admin/update", this.admins[arrayIndex])
        .then(function () {})
        .catch((e) => {
          console.log(e);
        });
    },

    camelCase: function (s) {
      s = s.replace(/\s/g, "");
      s = s.charAt(0).toLowerCase() + s.slice(1);
      return s;
    },
  },
  mounted: function () {
    this.getLinks();
    this.getAdmins();
  },
};
</script>
<style lang="scss" scoped>
.loginContainer {
  margin-top: 3%;

  width: 40%;
  margin-left: 30%;
  padding-top: 2%;
  padding-bottom: 7.5%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.title {
  color: #417e3e;
}
.inputContainer {
  padding-bottom: 1%;
  padding-top: 1%;
  width: 90%;
  margin-left: 5%;
  input {
    height: 25px;
    width: 100%;
  }
}
.email {
  display: flex;
  flex: 1;
  align-items: center;
}
.priv {
  display: flex;
  flex: 3;
}
.radio {
  display: flex;
  flex-wrap: wrap;
  div {
    padding: 2%;
  }
  input {
    height: 1.2em;
  }
  label {
    font-size: 14px;
  }
}
.btnContainer {
  padding: 5%;
  align-items: center;
  display: flex;
  flex: 1;
}
.loginBtn {
  box-shadow: 0px 10px 14px -7px #3e7327;
  background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
  background-color: #417e3e;
  border-radius: 4px;
  border: 1px solid #4b8f29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 28px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #5b8a3c;
}
.loginBtn:hover {
  background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
  background-color: #346431;
}
.loginBtn:active {
  position: relative;
  top: 1px;
}
.redBtn {
  box-shadow: 0px 10px 14px -7px #3e7327;
  background: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
  background-color: #b31217;
  border-radius: 4px;
  border: 1px solid #e52d27;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 28px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #e52d27;
}
.redBtn:hover {
  background-position: right center;
}
.redBtn:active {
  position: relative;
  top: 1px;
}

.err {
  color: #ff0033;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
}
</style>
