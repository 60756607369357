<template>
  <div class="">
    <div class="">
      <h1 class="title">Add A New Order</h1>
      <div class="flexcontainer">
        <div class="shippingContainer">
          <h2 class="title">Shipping Information</h2>
          <div class="inputContainer">
            <input
              v-model="shipping.name"
              type="text"
              placeholder="Full Name"
            />
            <p class="err">{{ tierNameErrorMsg }}</p>
          </div>
          <div class="inputContainer">
            <input
              v-model="shipping.address1"
              type="text"
              placeholder="Address Line 1"
            />
            <p class="err">{{ tierNameErrorMsg }}</p>
          </div>
          <div class="inputContainer">
            <input
              v-model="shipping.address2"
              type="text"
              placeholder="Address Line 2"
            />
            <p class="err">{{ tierNameErrorMsg }}</p>
          </div>
          <div class="inputContainer">
            <input v-model="shipping.city" type="text" placeholder="City" />
            <p class="err">{{ tierNameErrorMsg }}</p>
            <input v-model="shipping.state" type="text" placeholder="State" />
            <p class="err">{{ tierNameErrorMsg }}</p>
            <input
              v-model="shipping.zipcode"
              type="text"
              placeholder="Zipcode"
            />
            <p class="err">{{ tierNameErrorMsg }}</p>
          </div>
          <div class="inputContainer">
            <select v-model="shipping.country" id="country" name="country">
              <option value="Afganistan">Afghanistan</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antigua & Barbuda">Antigua & Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bonaire">Bonaire</option>
              <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
              <option value="Botswana">Botswana</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Ter">
                British Indian Ocean Ter
              </option>
              <option value="Brunei">Brunei</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Canary Islands">Canary Islands</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Channel Islands">Channel Islands</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos Island">Cocos Island</option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote DIvoire">Cote DIvoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Curaco">Curacao</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="East Timor">East Timor</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands">Falkland Islands</option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Ter">French Southern Ter</option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Great Britain">Great Britain</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guinea">Guinea</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="Indonesia">Indonesia</option>
              <option value="India">India</option>
              <option value="Iran">Iran</option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea North">Korea North</option>
              <option value="Korea Sout">Korea South</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Laos">Laos</option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libya">Libya</option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macau">Macau</option>
              <option value="Macedonia">Macedonia</option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Malawi">Malawi</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Midway Islands">Midway Islands</option>
              <option value="Moldova">Moldova</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Nambia">Nambia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherland Antilles">Netherland Antilles</option>
              <option value="Netherlands">Netherlands (Holland, Europe)</option>
              <option value="Nevis">Nevis</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau Island">Palau Island</option>
              <option value="Palestine">Palestine</option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Phillipines">Philippines</option>
              <option value="Pitcairn Island">Pitcairn Island</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Republic of Montenegro">
                Republic of Montenegro
              </option>
              <option value="Republic of Serbia">Republic of Serbia</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russia">Russia</option>
              <option value="Rwanda">Rwanda</option>
              <option value="St Barthelemy">St Barthelemy</option>
              <option value="St Eustatius">St Eustatius</option>
              <option value="St Helena">St Helena</option>
              <option value="St Kitts-Nevis">St Kitts-Nevis</option>
              <option value="St Lucia">St Lucia</option>
              <option value="St Maarten">St Maarten</option>
              <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
              <option value="St Vincent & Grenadines">
                St Vincent & Grenadines
              </option>
              <option value="Saipan">Saipan</option>
              <option value="Samoa">Samoa</option>
              <option value="Samoa American">Samoa American</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome & Principe">Sao Tome & Principe</option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syria">Syria</option>
              <option value="Tahiti">Tahiti</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania">Tanzania</option>
              <option value="Thailand">Thailand</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad & Tobago">Trinidad & Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks & Caicos Is">Turks & Caicos Is</option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Erimates">United Arab Emirates</option>
              <option selected value="United States of America">
                United States of America
              </option>
              <option value="Uraguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Vatican City State">Vatican City State</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Vietnam">Vietnam</option>
              <option value="Virgin Islands (Brit)">
                Virgin Islands (Brit)
              </option>
              <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
              <option value="Wake Island">Wake Island</option>
              <option value="Wallis & Futana Is">Wallis & Futana Is</option>
              <option value="Yemen">Yemen</option>
              <option value="Zaire">Zaire</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            <p class="err">{{ tierNameErrorMsg }}</p>
          </div>
        </div>
        <div class="productsContainer">
          <h2 class="title">Select Products</h2>
          <div class="inputContainer">
            <select v-model="productSelected">
              <option
                v-for="(product, index) in products"
                :key="index"
                :value="index"
              >
                {{ product.name }}
              </option>
            </select>

            <select class="" v-if="productsLoaded" v-model="priceTierSelected">
              <option
                v-for="(weight, index) in priceTierMap[
                  products[productSelected].priceTier
                ].weights"
                :key="index"
                :value="index"
              >
                {{ weight }}g
              </option>
            </select>
            <select
              class="marginRight"
              v-if="productsLoaded"
              v-model="quantitySelected"
            >
              <option v-for="n in 10" :key="n" :value="n">Qty: {{ n }}</option>
            </select>
            <button v-on:click="addProduct()" class="loginBtn">
              Add Product
            </button>
          </div>
          <p class="err">{{ productsErrorMsg }}</p>
        </div>
      </div>
      <div class="">
        <h2 class="title">Products</h2>
        <div class="">
          <div class="flexcontainer product">
            <p class="flex1">Name</p>
            <p class="flex1">Weight</p>
            <p class="flex1">Quantity</p>
            <p class="flex1">Price</p>
          </div>
          <div
            v-for="(product, index) in addedProducts"
            :key="index"
            class="product"
          >
            <div class="flexcontainer">
              <p class="flex1">{{ product.product.name }}</p>
              <p class="flex1">{{ product.priceTier.weight }}g</p>
              <p class="flex1">{{ product.quantity }}</p>
              <p class="flex1">
                ${{ (product.priceTier.price * product.quantity).toFixed(2) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="btnContainer1">
        <button v-on:click="createOrder()" class="loginBtn">
          Create Order
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminAddOrder",
  props: {},
  data: function () {
    return {
      shipping: {
        name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "United States of America",
      },
      priceTier: {
        tier: "",
        prices: new Array(8),
      },
      priceTiers: [],
      priceTierMap: {},
      products: [],
      addedProducts: [],
      product: {},
      productSelected: 0,
      quantitySelected: 1,
      productsLoaded: false,
      priceTierSelected: 0,

      tierNameErrorMsg: "",
      productsErrorMsg: "",
    };
  },
  methods: {
    getProducts: function () {
      axios
        .get(this.$hostname + "/api/admin/products")
        .then((response) => {
          if (response.data) {
            for (var i in response.data) {
              var pictures = response.data[i].picture.split(",");
              response.data[i].picture = pictures;
              this.products.push(response.data[i]);
            }
            this.productsLoaded = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addProduct: function () {
      var data = {
        priceTier: {
          weight:
            this.priceTierMap[this.products[this.productSelected].priceTier]
              .weights[this.priceTierSelected],
          id: this.priceTierMap[this.products[this.productSelected].priceTier]
            .ids[this.priceTierSelected],
          price:
            this.priceTierMap[this.products[this.productSelected].priceTier]
              .prices[this.priceTierSelected],
        },
        product: this.products[this.productSelected],
        quantity: this.quantitySelected,
      };
      this.addedProducts.push(data);
    },
    createOrder: function () {
      this.tierNameErrorMsg = "";
      this.productsErrorMsg = "";
      for (var i in this.shipping) {
        if (this.shipping[i] == "" && i != "address2") {
          this.tierNameErrorMsg = "Must fill out shipping information";
        }
      }
      if (this.addedProducts.length > 0) {
        //insert into DB
        var data = {
          shipping: this.shipping,
          products: this.addedProducts,
        };
        axios
          .post(this.$hostname + "/api/admin/order/add", data)
          .then((response) => {
            if (response.data.affectedRows == 1) {
              //success lets clear the data
              this.addedProducts = [];
              for (var i in this.shipping) {
                if (i != "country") {
                  this.shipping[i] = "";
                }
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        //otherwise log no products
        this.productsErrorMsg = "Must add products";
      }
    },

    addPriceTiers: function () {
      axios
        .post(this.$hostname + "/api/admin/price-tier/add", this.priceTier)
        .then((response) => {
          if (response.data == true) {
            this.priceTiers.push(this.priceTier);
          } else {
            if (response.data.tier == false) {
              this.tierNameErrorMsg = "Please enter a valid tier name";
              return;
            }
            this.$router.go();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPriceTiers: function () {
      axios
        .get(this.$hostname + "/api/admin/price-tiers")
        .then((response) => {
          if (response.data) {
            for (var i in response.data) {
              var weights = response.data[i].weights.split(",");
              var pricesParse = response.data[i].prices.split(",");
              var ids = response.data[i].ids.split(",");

              this.priceTiers.push({
                tier: response.data[i].tier,
                weights: weights,
                ids: ids,
                prices: pricesParse,
              });
            }
            this.makePriceTierMap();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    makePriceTierMap: function () {
      for (var i in this.priceTiers) {
        this.priceTierMap[this.priceTiers[i].tier] = this.priceTiers[i];
      }
      console.log(this.priceTierMap);
    },
    updatePriceTier: function (i) {
      axios
        .post(
          this.$hostname + "/api/admin/price-tier/update",
          this.priceTiers[i]
        )
        .then((response) => {
          console.log(response.data);
        });
    },
    deletePriceTier: function (i) {
      axios
        .post(this.$hostname + "/api/admin/price-tier/delete", {
          tier: this.priceTiers[i].tier,
        })
        .then((response) => {
          if (response.data) {
            this.priceTiers.splice(i, 1);
          }
        });
    },
  },
  mounted: function () {
    this.getProducts();
    this.getPriceTiers();
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .flexcontainer {
    display: flex;
  }
  .flex1 {
    flex: 1;
    text-align: center;
  }
  .marginRight {
    margin-right: 5%;
  }
  .shippingContainer {
    padding-left: 5%;

    flex: 1;
  }
  .productsContainer {
    padding: 0 5%;
    flex: 1;
  }
  .product {
    padding: 0 5%;
    text-align: left;
    flex: 1;
    border-bottom: 1px solid #eeeeee;
  }
  .loginContainer {
    margin-top: 3%;

    width: 40%;
    margin-left: 30%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    display: flex;

    input {
      height: 25px;
      margin-left: 0.5%;
      margin-right: 0.5%;
      width: 100%;
    }
  }
  .email {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .priv {
    display: flex;
    flex: 3;
  }
  .radio {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 2%;
    }
    input {
      height: 1.2em;
    }
    label {
      font-size: 14px;
    }
  }
  .btnContainer {
    padding: 5%;
    align-items: center;
    display: flex;
    flex: 1;
  }
  .btnContainer1 {
    padding: 2%;
    align-items: center;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .redBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(
      to right,
      #e52d27 0%,
      #b31217 51%,
      #e52d27 100%
    );
    background-color: #b31217;
    border-radius: 4px;
    border: 1px solid #e52d27;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #e52d27;
  }
  .redBtn:hover {
    background-position: right center;
  }
  .redBtn:active {
    position: relative;
    top: 1px;
  }

  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .flexcontainer {
  }
  .flex1 {
    flex: 1;
    text-align: center;
  }
  .marginRight {
    margin-right: 5%;
  }
  .shippingContainer {
    padding-left: 5%;

    flex: 1;
  }
  .productsContainer {
    padding: 0 5%;
    flex: 1;
  }
  .product {
    padding: 0 5%;
    text-align: left;
    flex: 1;
    border-bottom: 1px solid #eeeeee;
  }
  .loginContainer {
    margin-top: 3%;

    width: 40%;
    margin-left: 30%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    display: flex;
    flex-wrap: wrap;

    input {
      height: 2em;
      margin-left: 0.5%;
      margin-right: 0.5%;
      width: 100%;
    }
    select {
      height: 2em;
      margin-bottom: 2em;
    }
  }
  .email {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .priv {
    display: flex;
    flex: 3;
  }
  .radio {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 2%;
    }
    input {
      height: 1.2em;
    }
    label {
      font-size: 14px;
    }
  }
  .btnContainer {
    padding: 5%;
    align-items: center;
    display: flex;
    flex: 1;
  }
  .btnContainer1 {
    padding: 2%;
    align-items: center;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .redBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(
      to right,
      #e52d27 0%,
      #b31217 51%,
      #e52d27 100%
    );
    background-color: #b31217;
    border-radius: 4px;
    border: 1px solid #e52d27;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #e52d27;
  }
  .redBtn:hover {
    background-position: right center;
  }
  .redBtn:active {
    position: relative;
    top: 1px;
  }

  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}
</style>
