<template>
  <div>
    <div class="shop-container">
      <div
        class="card-background"
        v-for="(product, index) in products"
        :key="product.name"
      >
        <div class="smallImg">
          <div class="imgContainer">
            <div
              v-for="(pic, i) in product.picture"
              :key="i"
              @click="
                choosePic(pic);
                setImgActive(i);
              "
              v-bind:class="{ imgIsActive: imgIsActive[i] }"
            >
              <img class="productImg" alt="Hemp Flower" :src="getImgUrl(pic)" />
            </div>
          </div>
        </div>
        <div class="bigImg">
          <img class="productImg" alt="Hemp Flower" :src="getImgUrl(pic)" />
        </div>
        <div class="detailsContainer">
          <div class="productName">
            <h1>{{ product.name }}</h1>
          </div>
          <div class="productDescription">
            <p v-for="(d, i) in product.description" :key="i">{{ d }}</p>
          </div>
          <div class="productPrice">
            <h2>${{ price(index, product.weightKey, product.qty) }}</h2>
          </div>
          <div class="productWeight">
            <button
              @click="setActive(weightindex, index)"
              v-bind:class="{ active: isActive[weightindex] }"
              class="weightOption"
              v-for="(weight, weightindex) in product.weights"
              :key="weight"
              :value="weightindex"
            >
              {{ weight }}g
            </button>
          </div>
          <div class="productQuantity">
            <p>Select Quantity</p>
            <input
              v-model="product.qty"
              type="number"
              id="quantity"
              name="quantity"
              min="1"
              :max="
                product.qtyGrams / product.weights[products[index].weightKey]
              "
            />
          </div>
          <div class="btnContainer">
            <button v-on:click="updateCart(index, product.qty)" class="atc">
              Add To Cart
            </button>
            <button
              v-on:click="
                updateCart(index, product.qty);
                $router.push('/cart');
              "
              class="weightOption"
            >
              Buy It Now
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="shop-container1">
      <div
        class="card-background"
        v-for="(product, index) in products"
        :key="product.name"
      >
        <div class="bigImg">
          <img class="productImg" alt="Hemp Flower" :src="getImgUrl(pic)" />
        </div>
        <div class="smallImg">
          <div class="imgContainer">
            <div
              v-for="(pic, i) in product.picture"
              :key="i"
              @click="
                choosePic(pic);
                setImgActive(i);
              "
              v-bind:class="{ imgIsActive: imgIsActive[i] }"
            >
              <img class="productImg" alt="Hemp Flower" :src="getImgUrl(pic)" />
            </div>
          </div>
        </div>
        <div class="detailsContainer">
          <div class="productName">
            <h1>{{ product.name }}</h1>
          </div>
          <div class="productDescription">
            <p v-for="(d, i) in product.description" :key="i">{{ d }}</p>
          </div>
          <div class="productPrice">
            <h2>${{ price(index, product.weightKey, product.qty) }}</h2>
          </div>
          <div class="productWeight">
            <button
              @click="setActive(weightindex, index)"
              v-bind:class="{ active: isActive[weightindex] }"
              class="weightOption"
              v-for="(weight, weightindex) in product.weights"
              :key="weight"
              :value="weightindex"
            >
              {{ weight }}g
            </button>
          </div>
          <div class="productQuantity">
            <p>Select Quantity</p>
            <input
              v-model="product.qty"
              type="number"
              id="quantity"
              name="quantity"
              min="1"
              :max="
                product.qtyGrams / product.weights[products[index].weightKey]
              "
            />
          </div>
          <div class="btnContainer">
            <button v-on:click="updateCart(index, product.qty)" class="atc">
              Add To Cart
            </button>
            <button
              v-on:click="
                updateCart(index, product.qty);
                $router.push('/cart');
              "
              class="weightOption"
            >
              Buy It Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import axios from "axios";

export default {
  name: "ProductDetails",
  props: {
    productName: String,
  },
  data() {
    return {
      pic: "",
      products: [],
      isActive: [true, false, false, false, false, false, false, false],
      imgIsActive: [true, false, false, false],
    };
  },
  methods: {
    getImgUrl: function (pic) {
      return this.$hostname + "/uploads/" + pic;
    },
    updateCart: function (productIndex, qty) {
      eventBus.$emit("atc", qty);
      //update cart in database
      axios.post(this.$hostname + "/api/cart/add", this.products[productIndex]);
    },
    price: function (productIndex, weightKey, qty) {
      return (this.products[productIndex].prices[weightKey] * qty).toFixed(2);
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName("card")[0];
      console.log(el);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    getProducts() {
      axios
        .get(this.$hostname + "/api/products")
        .then((response) => {
          for (var i in response.data) {
            response.data[i].qty = "1";
            response.data[i].priceTierIds =
              response.data[i].priceTierIds.split(",");
            response.data[i].weights = response.data[i].weights.split(",");
            response.data[i].weightKey = "0";
            response.data[i].prices = response.data[i].prices.split(",");
            response.data[i].picture = response.data[i].picture.split(",");
          }

          this.products = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProduct() {
      axios
        .post(this.$hostname + "/api/product", {
          productName: this.$props.productName,
        })
        .then((response) => {
          for (var i in response.data) {
            response.data[i].qty = "1";
            response.data[i].priceTierIds =
              response.data[i].priceTierIds.split(",");
            response.data[i].weights = response.data[i].weights.split(",");
            response.data[i].weightKey = 0;
            response.data[i].prices = response.data[i].prices.split(",");
            response.data[i].picture = response.data[i].picture.split(",");
            response.data[i].description =
              response.data[i].description.split(/\r?\n|\r/);
          }
          console.log(response.data);
          this.products = response.data;
          this.pic = this.products[0].picture[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    choosePic(pic) {
      this.pic = pic;
    },
    setImgActive(index) {
      for (var i in this.imgIsActive) {
        this.imgIsActive[i] = false;
      }
      this.imgIsActive[index] = true;
    },
    setActive(weightIndex, index) {
      for (var i in this.isActive) {
        this.isActive[i] = false;
      }
      this.isActive[weightIndex] = true;
      this.products[index].weightKey = weightIndex;
    },
  },
  computed: {},
  mounted: function () {
    //this.getProducts();
    this.getProduct();
  },
  updated: function () {
    if (this.scroll == 1) {
      this.scrollToElement();
    }
    var elements = this.$el.querySelectorAll(".smallImg > .imgContainer > div");
    for (var i in elements) {
      let width = elements[i].offsetWidth;
      if (width == undefined || width == null) {
        return;
      }
      console.log(elements[i]);
      elements[i].style.height = width + "px";
    }
    window.addEventListener("resize", function () {
      var elements = this.$el.querySelectorAll(
        ".smallImg > .imgContainer > div"
      );
      for (var i in elements) {
        let width = elements[i].offsetWidth;
        if (width == undefined || width == null) {
          return;
        }
        console.log(elements[i]);
        elements[i].style.height = width + "px";
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .shop-container1 {
    display: none;
  }
  .shop-container {
    padding: 5%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .card-background {
    width: 100%;
    padding: 2px;
    margin-bottom: 2%;
    display: flex;
    .smallImg {
      flex: 1;
      .imgContainer {
        .imgIsActive {
          border: 2px solid #417e3e;
        }
        div {
          border: 1px solid #eeeeee;
          height: 100%;
          overflow-y: hidden;
          position: relative;
          margin: 4% 0;
        }
        img {
          max-height: 100%;
          max-width: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;
        }
      }
    }
    .bigImg {
      flex: 4;
      display: flex;
      padding: 0 2%;
      img {
        width: 100%;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
    .detailsContainer {
      flex: 4;
      text-align: left;
      .productName {
      }
      .productWeight {
        display: flex;
        flex-wrap: wrap;
        .active {
          color: #fff;
          box-shadow: 0px 10px 14px -7px #3e7327;
          background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
          background-color: #417e3e;
          border-radius: 4px;
          border: 0px solid #fff;
          text-shadow: 0px 1px 0px #5b8a3c;
        }
        .weightOption {
          flex: 46%;
          margin: 1% 2%;
        }
      }
      .productQuantity {
        padding: 4% 2%;
        input {
          width: 100%;

          line-height: 2.5em;
        }
      }
    }
  }
  .card {
    width: 100%;
  }
  .imgContainer {
    width: 100%;
    .productImg {
      width: 50%;
    }
  }

  .btnContainer {
    display: flex;
    flex-wrap: wrap;
    padding-top: 8%;
    button {
      flex: 46%;
      margin: 2%;
    }
  }
  .atc {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
    line-height: 2.6em;
  }
  .atc:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .atc:active {
    position: relative;
    top: 1px;
  }
  .weightOption {
    background-color: #fff;
    border-radius: 0;
    border: 2px solid #417e3e;

    cursor: pointer;
    color: #417e3e;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
  }

  .weightOption:active {
    position: relative;
    top: 1px;
  }
}

@media (max-width: 767px) {
  .shop-container1 {
    padding-top: 0%;
    width: 100%;
  }
  .shop-container {
    display: none;
  }
  .card-background {
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 2%;
  }

  .card-background {
    width: 100%;
    margin-bottom: 2%;
    .smallImg {
      .imgContainer {
        display: flex;
        width: 100%;
        .imgIsActive {
          border: 2px solid #417e3e;
        }
        div {
          flex: 25%;
          border: 1px solid #eeeeee;
          height: 100%;
          overflow-y: hidden;
          position: relative;
          margin: 4% 2%;
        }
        img {
          max-height: 100%;
          max-width: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;
        }
      }
    }
    .bigImg {
      display: flex;
      padding: 0 2%;
      img {
        width: 100%;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
    .detailsContainer {
      padding: 2%;
      text-align: left;
      .productName {
      }
      .productWeight {
        display: flex;
        flex-wrap: wrap;
        .active {
          color: #fff;
          box-shadow: 0px 10px 14px -7px #3e7327;
          background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
          background-color: #417e3e;
          border-radius: 4px;
          border: 0px solid #fff;
          text-shadow: 0px 1px 0px #5b8a3c;
        }
        .weightOption {
          flex: 46%;
          margin: 1% 2%;
        }
      }
      .productQuantity {
        padding: 4% 2%;
        input {
          width: 100%;

          line-height: 2.5em;
        }
      }
    }
  }

  .card {
    width: 100%;
  }
  .imgContainer {
    width: 100%;
    .productImg {
      width: 50%;
    }
  }

  .btnContainer {
    padding: 10% 0;
    display: flex;
    justify-content: space-evenly;
  }
  .atc {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 10%;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .atc:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .atc:active {
    position: relative;
    top: 1px;
  }

  .weightOption {
    background-color: #fff;
    border-radius: 0;
    border: 2px solid #417e3e;

    cursor: pointer;
    color: #417e3e;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 8px 10%;
    text-decoration: none;
  }

  .weightOption:active {
    position: relative;
    top: 1px;
  }
}
</style>
