<template>
  <div v-if="cart.length > 0" class="container">
    <div class="checkoutInfo">
      <div v-if="address" class="shippingcontainer">
        <div class="step">
          <h2>1</h2>
        </div>
        <div class="header">
          <h2>Shipping Address</h2>
        </div>
        <div class="address">
          <p v-if="shippingAddresses.length > 0">
            {{ shippingAddresses[shippingAddressSelected].name }}
          </p>
          <p v-if="shippingAddresses.length > 0">
            {{ shippingAddresses[shippingAddressSelected].address1
            }}{{
              address2Format(
                shippingAddresses[shippingAddressSelected].address2
              )
            }},
          </p>
          <p v-if="shippingAddresses.length > 0">
            {{ shippingAddresses[shippingAddressSelected].city }},
            {{ shippingAddresses[shippingAddressSelected].state }},
            {{ shippingAddresses[shippingAddressSelected].zipcode }}
          </p>
          <p v-if="shippingAddresses.length > 0">
            {{ shippingAddresses[shippingAddressSelected].country }}
          </p>
        </div>
        <div class="change">
          <a v-on:click="changeShippingAddress()">Change</a>
        </div>
      </div>
      <div v-if="!address" class="shippingaddresscontainer-container">
        <div class="shippingaddresscontainer">
          <div class="step">
            <h2>1</h2>
          </div>
          <div class="header">
            <h2>Choose a shipping address</h2>
          </div>

          <div class="change">
            <a v-on:click="closeShippingAddress()">Close</a>
          </div>
        </div>
        <div class="addresses">
          <div class="heading"><h2>Addresses</h2></div>
          <div class="alladdresses">
            <div
              v-on:click="selectShippingAddress(index)"
              v-for="(address, index) in shippingAddresses"
              :key="index"
              class="address"
            >
              <div class="input">
                <input
                  v-model="shippingAddressSelected"
                  type="radio"
                  name="1"
                  :value="index"
                />
                <label for="1"
                  ><span>{{ address.name }}</span> {{ address.address1
                  }}{{ address2Format(address.address2) }}, {{ address.city }},
                  {{ address.state }}, {{ address.zipcode }},
                  {{ address.country }}</label
                >
              </div>
              <div class="edit">
                <a v-on:click="editAddressPopup(index)">Edit address</a>
              </div>
            </div>
          </div>
          <div class="addAddress">
            <span v-on:click="addAddressPopup()"
              >+<a>Add a new address</a></span
            >
          </div>
        </div>
      </div>
      <div v-if="payment" class="paymentcontainer-container">
        <div v-if="payment" class="paymentcontainer">
          <div class="step">
            <h2>2</h2>
          </div>
          <div class="header">
            <h2>Payment Method</h2>
          </div>
          <div class="address">
            <p>
              <span>{{ brandFormat() }}</span
              >{{ last4Format() }}
            </p>
          </div>
          <div class="change">
            <a v-on:click="changePaymentMethod()">Change</a>
          </div>
        </div>
        <div v-if="payment" class="paymentcontainer">
          <div class="step displayNone">
            <h2></h2>
          </div>
          <div class="header displayNone">
            <h2></h2>
          </div>

          <div class="address">
            <p><span>Add a gift card or promotion code</span></p>
          </div>

          <div class="change displayNone">
            <a></a>
          </div>
        </div>
        <div v-if="payment" class="paymentcontainer">
          <div class="step displayNone">
            <h2></h2>
          </div>
          <div class="header displayNone">
            <h2></h2>
          </div>

          <div class="address">
            <input
              v-model="promoCode.name"
              type="text"
              placeholder="Enter code"
            /><button v-on:click="applyPromoCode()" class="apply">Apply</button>
          </div>

          <div class="change displayNone">
            <a></a>
          </div>
        </div>
        <div v-if="payment" class="paymentcontainer">
          <div class="step displayNone">
            <h2></h2>
          </div>
          <div class="header displayNone">
            <h2></h2>
          </div>

          <div class="address">
            <p class="err">{{ promoCodeErr }}</p>
          </div>

          <div class="change displayNone">
            <a></a>
          </div>
        </div>
      </div>
      <div v-if="choosepayment" class="choosepaymentcontainer">
        <div class="choosepaymentheadingcontainer">
          <div class="step">
            <h2>2</h2>
          </div>
          <div class="header">
            <h2>Choose a Payment Method</h2>
          </div>

          <div class="change">
            <a v-on:click="closePaymentMethod()">Close</a>
          </div>
        </div>
        <div class="addresses">
          <div v-if="cards.length > 0" class="heading">
            <h2>Your credit and debit cards</h2>
          </div>
          <div v-if="cards.length > 0" class="subheading">
            <p class="flex8"></p>
            <p>Name on Card</p>
            <p>Expires On</p>
          </div>
          <div v-if="cards.length > 0" class="alladdresses">
            <div
              v-on:click="selectCard(index)"
              v-for="(card, index) in cards"
              :key="index"
              class="address"
            >
              <div class="input">
                <input
                  v-model="cardSelected"
                  type="radio"
                  name="3"
                  :value="index"
                />
                <label for="3"
                  ><span>{{ card.brand }}</span> ending in
                  {{ card.last4 }}</label
                >
              </div>
              <div class="name">
                <p>{{ getNameOnCardFormat(card.billingId) }}</p>
              </div>
              <div class="exp">
                <p>
                  {{ card.expMonth.toString() }}/{{ card.expYear.toString() }}
                </p>
              </div>
            </div>
          </div>
          <div class="addAddress">
            <span v-on:click="addCardPopup()"
              >+<a>Add a credit or debit card</a></span
            >
          </div>
        </div>
        <div v-if="nowPaymentsOnline" class="addresses">
          <div class="heading"><h2>Pay with cryptocurrency</h2></div>
          <div v-if="nowPaymentCurrencies.length > 0" class="subheading">
            <p class="flex8">Deposit Address</p>
            <p>
              Estimated Price ({{
                nowPaymentCurrencies[selectedNowPaymentCurrency].toUpperCase()
              }})
            </p>
            <p v-if="nowPaymentAddress != '' && timer != '00:00:00'">
              Expires In
            </p>
          </div>
          <div v-if="nowPaymentCurrencies.length > 0" class="alladdresses">
            <div class="address">
              <div class="input">
                <select
                  v-if="nowPaymentAddress == ''"
                  v-on:change="getNowPaymentEstimate()"
                  v-model="selectedNowPaymentCurrency"
                >
                  <option
                    v-for="(currency, index) in nowPaymentCurrencies"
                    :key="index"
                    :value="index"
                  >
                    {{ currency.toUpperCase() }}
                  </option>
                </select>
                <p v-if="nowPaymentAddress != ''">{{ nowPaymentAddress }}</p>
              </div>
              <div class="name">
                <p>
                  {{ nowPaymentEstimate }}
                  {{
                    nowPaymentCurrencies[
                      selectedNowPaymentCurrency
                    ].toUpperCase()
                  }}
                </p>
              </div>
              <div
                v-if="nowPaymentAddress != '' && timer != '00:00:00'"
                class="exp"
              >
                <p>{{ timer }}</p>
              </div>
            </div>
          </div>
          <div class="addAddress">
            <button
              v-if="nowPaymentAddress == ''"
              @click="createCryptoOrder()"
              class="loginBtn"
            >
              Create a deposit address
            </button>
            <p v-if="nowPaymentAddress != '' && timer != '00:00:00'">
              Please send
              <span class="green"
                >{{ nowPaymentEstimate }} ({{
                  nowPaymentCurrencies[
                    selectedNowPaymentCurrency
                  ].toUpperCase()
                }})</span
              >
              to <span class="green">{{ nowPaymentAddress }}</span> within
              {{ timer }}
            </p>
            <p v-if="nowPaymentAddress != '' && timer != '00:00:00'">
              After receiving enough confirmations you will be sent an order
              confirmation email and shipping updates.
            </p>
            <p v-if="nowPaymentAddress != '' && timer != '00:00:00'">
              Payment Status: {{ nowPaymentStatus }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="billing" class="billingaddresscontainer">
        <div class="shippingaddresscontainer">
          <div class="step">
            <h2>2</h2>
          </div>
          <div class="header">
            <h2>Choose a billing address</h2>
          </div>

          <div class="change"></div>
        </div>
        <div class="addresses">
          <div v-if="billingAddresses.length > 0" class="heading">
            <h2>Addresses</h2>
          </div>
          <div v-if="billingAddresses.length > 0" class="alladdresses">
            <div
              v-on:click="selectBillingAddress(index)"
              v-for="(address, index) in billingAddresses"
              :key="index"
              class="address"
            >
              <div class="input">
                <input
                  v-model="billingAddressSelected"
                  type="radio"
                  name="2"
                  :value="index"
                />
                <label for="1"
                  ><span>{{ address.name }}</span> {{ address.address1
                  }}{{ address2Format(address.address2) }}, {{ address.city }},
                  {{ address.state }}, {{ address.zipcode }},
                  {{ address.country }}</label
                >
              </div>
            </div>
          </div>
          <div v-if="billingAddresses.length > 0" class="btnContainer">
            <button
              v-on:click="
                attachBillingToCard(billingAddresses[billingAddressSelected].id)
              "
              class="loginBtn"
            >
              Use this billing address
            </button>
          </div>
          <div class="addAddress">
            <span v-on:click="addBillingAddressPopup()"
              >+<a>Add a new address</a></span
            >
          </div>
        </div>
      </div>
      <div class="reviewcontainer-container">
        <div class="reviewcontainer">
          <div class="step">
            <h2>3</h2>
          </div>
          <div class="header">
            <h2>Review Items &#38; Shipping</h2>
          </div>
        </div>
        <div class="itemscontainer">
          <h3 v-if="shipDates.length > 0">
            Estimated Delivery: {{ getShipMonth(shipDates[shipDateSelected].date) }} {{ shipDates[shipDateSelected].date.getDate() }},
            {{ shipDates[shipDateSelected].date.getFullYear() }}
          </h3>
          <p>Items shipped from USCannabis.co</p>
          <div class="same-shipping">
            <div class="allItems">
              <div v-for="(item, index) in cart" :key="index" class="items">
                <div class="image">
                  <img v-bind:src="getImgURL(index)" />
                </div>
                <div class="item">
                  <h3>
                    {{ item.name
                    }}<span> ({{ getWeight(item.weightGrams) }})</span>
                  </h3>
                  <p>${{ (item.quantity * item.price).toFixed(2) }}</p>
                  <div class="options">
                    Qty:
                    <input
                      @change="updateChangeArray(index, $event)"
                      type="number"
                      id="quantity"
                      name="quantity"
                      min="1"
                      :value="cart[index].quantity"
                    />
                    <p>|</p>
                    <a v-on:click="deleteCartItem(item.id, index)">Delete</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="shippingAddresses.length > 0" class="shipping">
              <p class="heading">Choose your delivery date:</p>
              <div v-for="(deliveryDate, index) in shipDates" :key="index">
                <input v-model="shipDateSelected" type="radio" name="'s-' 3" :value="index" />
                <label for="1"
                  >{{ getShipDay(deliveryDate.date) }}, {{ getShipMonth(deliveryDate.date) }} {{ deliveryDate.date.getDate()
                  }}<br
                /></label>
                <p class="shipping-cost">{{ deliveryDate.price }} Delivery</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="placeOrderContainer">
      <div class="btnContainer">
        <button v-on:click="placeYourOrder()" class="checkoutBtn">
          Place your order
        </button>
      </div>
      <p>
        By placing your order, you agree to U.S. Cannabis Co's privacy notice
        and conditions of use.
      </p>
      <div class="orderTotal">
        <h2>Order Summary</h2>
        <div class="table-item">
          <p class="left">Items ({{ getQuantity() }}):</p>
          <p class="right">${{ getSubtotal() }}</p>
        </div>
        <div v-if="promoCode.percent > 0.0" class="table-item">
          <p class="left">Promo code:</p>
          <p class="right">
            -${{
              ((getSubtotal() * parseFloat(promoCode.percent)) / 100).toFixed(2)
            }}
          </p>
        </div>
        <div class="table-item">
          <p class="left">Shipping &#38; Handling:</p>
          <p class="right">${{ getShippingCost().toFixed(2) }}</p>
        </div>
        <div class="tally">
          <p></p>
          <p class="underline"></p>
        </div>
        <div class="table-item">
          <p class="left">Total before tax:</p>
          <p class="right">
            ${{
              (
                getSubtotal() -
                (getSubtotal() * parseFloat(promoCode.percent)) / 100
                + parseFloat(getShippingCost())
              ).toFixed(2)
            }}
          </p>
        </div>
        <div class="table-item">
          <p class="left">Estimated tax:</p>
          <p class="right">
            ${{
              (
                (getSubtotal() -
                  (getSubtotal() * parseFloat(promoCode.percent)) / 100) *
                0.0925
              ).toFixed(2)
            }}
          </p>
        </div>
      </div>
      <div class="orderTotal">
        <div class="table-item padded-top">
          <h2 class="big left">Order Total:</h2>
          <h2 class="big right">
            ${{
              (
                (getSubtotal() -
                  (getSubtotal() * parseFloat(promoCode.percent)) / 100) *
                1.0925
                + getShippingCost()
              ).toFixed(2)
            }}
          </h2>
        </div>
      </div>
    </div>

    <div v-visible="popup" class="dark-overlay">
      <div class="login-container">
        <div>
          <a v-on:click="closePopup()" href="#"
            ><i class="close fas fa-times"></i
          ></a>
        </div>

        <div class="input-container">
          <div class="logo-container">
            <img
              class="logo"
              alt="USCannabis logo"
              src="../assets/uscannabisco.png"
            />
          </div>

          <div v-if="newAddress" class="inputs">
            <div>
              <p>Country/Region</p>
              <select
                v-model="shippingAddress.country"
                id="country"
                name="country"
              >
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option selected value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <p class="err">{{ err.shippingAddress.country }}</p>
            </div>
            <div>
              <p>Full name (First and Last name)</p>
              <input v-model="shippingAddress.name" type="text" />
              <p class="err">{{ err.shippingAddress.name }}</p>
            </div>
            <div v-if="!loggedin">
              <p>Email</p>
              <input v-model="shippingAddress.email" type="email" />
              <p class="err">{{ err.shippingAddress.email }}</p>
            </div>
            <div>
              <p>Phone number</p>
              <input v-model="shippingAddress.phone" type="text" />
            </div>
            <div>
              <p>Address</p>
              <input
                v-model="shippingAddress.address1"
                placeholder="Street address or P.O. Box"
                type="text"
              />
              <input
                v-model="shippingAddress.address2"
                placeholder="Apt, suite, unit, building, floor, etc"
                type="text"
              />
              <p class="err">{{ err.shippingAddress.address }}</p>
            </div>
            <div class="citystatezip">
              <div>
                <p>City</p>
                <input v-model="shippingAddress.city" type="text" />
                <p class="err">{{ err.shippingAddress.city }}</p>
              </div>
              <div>
                <p>State</p>
                <select v-model="shippingAddress.state" name="state" id="state">
                  <option value="" selected="selected">Select</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <p class="err">{{ err.shippingAddress.state }}</p>
              </div>
              <div>
                <p>Zipcode</p>
                <input v-model="shippingAddress.zipcode" type="text" />
                <p class="err">{{ err.shippingAddress.zipcode }}</p>
              </div>
            </div>
            <div class="btnContainer">
              <button v-on:click="useShippingAddress()" class="loginBtn">
                Use this shipping address
              </button>
            </div>
          </div>

          <div v-if="editAddress" class="inputs">
            <div>
              <p>Country/Region</p>
              <select
                v-model="shippingAddressEdit.country"
                id="country"
                name="country"
              >
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option selected value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <p class="err">{{ err.shippingAddress.country }}</p>
            </div>
            <div>
              <p>Full name (First and Last name)</p>
              <input v-model="shippingAddressEdit.name" type="text" />
              <p class="err">{{ err.shippingAddress.name }}</p>
            </div>
            <div v-if="!loggedin">
              <p>Email</p>
              <input v-model="shippingAddressEdit.email" type="email" />
              <p class="err">{{ err.shippingAddress.email }}</p>
            </div>
            <div>
              <p>Phone number</p>
              <input v-model="shippingAddressEdit.phone" type="text" />
            </div>
            <div>
              <p>Address</p>
              <input
                v-model="shippingAddressEdit.address1"
                placeholder="Street address or P.O. Box"
                type="text"
              />
              <input
                v-model="shippingAddressEdit.address2"
                placeholder="Apt, suite, unit, building, floor, etc"
                type="text"
              />
              <p class="err">{{ err.shippingAddress.address }}</p>
            </div>
            <div class="citystatezip">
              <div>
                <p>City</p>
                <input v-model="shippingAddressEdit.city" type="text" />
                <p class="err">{{ err.shippingAddress.city }}</p>
              </div>
              <div>
                <p>State</p>
                <select
                  v-model="shippingAddressEdit.state"
                  name="state"
                  id="state"
                >
                  <option value="" selected="selected">Select</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <p class="err">{{ err.shippingAddress.state }}</p>
              </div>
              <div>
                <p>Zipcode</p>
                <input v-model="shippingAddressEdit.zipcode" type="text" />
                <p class="err">{{ err.shippingAddress.zipcode }}</p>
              </div>
            </div>
            <div class="btnContainer">
              <button v-on:click="updateShippingAddress()" class="loginBtn">
                Edit this shipping address
              </button>
            </div>
          </div>

          <div v-if="newBillingAddress" class="inputs">
            <div>
              <p>Country/Region</p>
              <select
                v-model="billingAddress.country"
                id="country1"
                name="country1"
              >
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <p class="err">{{ err.billingAddress.country }}</p>
            </div>
            <div>
              <p>Full name (First and Last name)</p>
              <input v-model="billingAddress.name" type="text" />
              <p class="err">{{ err.billingAddress.name }}</p>
            </div>
            <div>
              <p>Phone number</p>
              <input v-model="billingAddress.phone" type="text" />
            </div>
            <div>
              <p>Address</p>
              <input
                v-model="billingAddress.address1"
                placeholder="Street address or P.O. Box"
                type="text"
              />
              <input
                v-model="billingAddress.address2"
                placeholder="Apt, suite, unit, building, floor, etc"
                type="text"
              />
              <p class="err">{{ err.billingAddress.address }}</p>
            </div>
            <div class="citystatezip">
              <div>
                <p>City</p>
                <input v-model="billingAddress.city" type="text" />
                <p class="err">{{ err.billingAddress.city }}</p>
              </div>
              <div>
                <p>State</p>
                <select v-model="billingAddress.state" name="state" id="state">
                  <option value="" selected="selected">Select</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <p class="err">{{ err.billingAddress.state }}</p>
              </div>
              <div>
                <p>Zipcode</p>
                <input v-model="billingAddress.zipcode" type="text" />
                <p class="err">{{ err.billingAddress.zipcode }}</p>
              </div>
            </div>
            <div class="btnContainer">
              <button v-on:click="useBillingAddress()" class="loginBtn">
                Use this billing address
              </button>
            </div>
          </div>

          <div v-visible="newCard">
            <div class="cardContainer">
              <p>Card number</p>

              <div id="card-container"></div>
              <p class="err">{{ err.card }}</p>
            </div>

            <div class="btnContainer">
              <button v-on:click="checkYourCard()" class="loginBtn">
                Add your card
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import eventBus from "@/eventBus";
export default {
  name: "CartTable",
  props: {
    prevRoute: String,
  },

  data() {
    return {
      cart: [],
      change: {},
      address: false,
      payment: true,
      choosepayment: false,
      billing: false,
      popup: false,
      newAddress: false,
      editAddress: false,
      newCard: false,
      newBillingAddress: false,
      nowPaymentsOnline: false,
      years: [],
      shippingAddress: {
        country: "United States of America",
        name: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
      },
      shippingAddressEdit: {
        country: "United States of America",
        name: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
      },
      shippingAddresses: [],
      billingAddress: {
        country: "United States of America",
        name: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
      },
      billingAddresses: [],
      cards: [],
      shippingAddressSelected: "",
      billingAddressSelected: "",
      cardSelected: "",
      err: {
        shippingAddress: {
          country: "",
          name: "",
          email: "",
          address: "",
        },
        billingAddress: {
          country: "",
          name: "",
          address: "",
        },
        card: " ",
      },
      newCardId: "",
      shipDates: [],
      shipDate: new Date(),
      shipDateSelected: 0,
      loggedin: false,
      promoCode: {
        name: "",
        percent: 0,
      },
      promoCodeErr: "",
      nowPaymentConfig: {
        method: "get",
        url: "https://api.nowpayments.io/v1/",
        headers: {
          "x-api-key": "VWVM9X2-2904XM0-H6AC0FC-QJVQAA3",
          "Content-Type": "application/json",
        },
      },
      nowPaymentCurrencies: [],
      selectedNowPaymentCurrency: "18",
      nowPaymentEstimate: "",
      nowPaymentAddress: "",
      nowPaymentStatus: "",
      duration: 0,
      timer: "00:00:00",
    };
  },
  created() {
    this.getCart();
    this.countdown();
  },
  async mounted() {
    this.getLoginStatus();
    this.getShippingAddresses();
    this.getCards();
    this.getBillingAddresses();
    this.getNowPaymentsStatus();
    this.populateYears();
    this.updateShipDate();
    if (!window.Square) {
      console.log("Square.js failed to load properly");
    }
  },

  methods: {
    async initializeCard(payments) {
      const darkModeCardStyle = {
        ".input-container": {
          borderColor: "#000",
          borderRadius: "6px",
        },
        ".message-icon": {
          color: "#417e3e",
        },
        "input::placeholder": {
          color: "#000",
        },
      };
      this.card = await payments.card({ style: darkModeCardStyle });
      await this.card.attach("#card-container");

      return this.card;
    },
    async tokenizeCard() {
      try {
        const result = await this.card.tokenize();

        return result;
      } catch (e) {
        console.error(e);
      }
    },
    getNowPaymentsStatus: function () {
      var config = {
        method: "get",
        url: "https://api.nowpayments.io/v1/status",
        headers: {},
      };
      axios.defaults.withCredentials = false;
      axios
        .get(config.url)
        .then((response) => {
          axios.defaults.withCredentials = true;
          if (response.data.message == "OK") {
            this.nowPaymentsOnline = true;
            this.getNowPaymentCurrencies();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getNowPaymentCurrencies: function () {
      var config = {};
      Object.assign(config, this.nowPaymentConfig);
      config.url = config.url + "currencies";
      axios.defaults.withCredentials = false;
      axios(config)
        .then((response) => {
          axios.defaults.withCredentials = true;
          this.nowPaymentCurrencies = response.data.currencies;
          this.getNowPaymentEstimate();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getNowPaymentEstimate: function () {
      var config = {};
      Object.assign(config, this.nowPaymentConfig);

      var price = (
        (this.getSubtotal() -
          (this.getSubtotal() * parseFloat(this.promoCode.percent)) / 100) *
        1.0925 + parseFloat(this.getShippingCost())
      ).toFixed(2);

      config.url =
        config.url +
        "estimate?amount=" +
        price +
        "&currency_from=usd&currency_to=" +
        this.nowPaymentCurrencies[this.selectedNowPaymentCurrency];
      axios.defaults.withCredentials = false;
      axios(config)
        .then((response) => {
          axios.defaults.withCredentials = true;
          this.nowPaymentEstimate = response.data.estimated_amount;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getCart: function () {
      axios
        .get(this.$hostname + "/api/cart")
        .then((response) => {
          this.cart = response.data;
          this.parsePictures();
          if (this.cart.length == 0) {
            if (this.$props.prevRoute == "/checkout") {
              this.$router.push({ name: "Home" });
            }
            this.$router.push(this.$props.prevRoute);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getLoginStatus: function () {
      axios
        .get(this.$hostname + "/api/user/logged-in")
        .then((response) => {
          this.loggedin = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateShipDate: function () {
      console.log(this.shippingAddresses[this.shippingAddressSelected]);
      this.shipDates = [];
      this.shipDate = new Date();
      let hour = this.shipDate.getHours();
      let day = this.shipDate.getDay();
      //if in the USA we need a 3 day option and overnight
      if (this.shippingAddresses[this.shippingAddressSelected] == null || this.shippingAddresses[this.shippingAddressSelected].country == "United States of America"){
        //add 3 day option
        //if ordered before 10am it will ship today
        if (hour < 10) {
          //skip sunday
          if (day + 3 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 3);
          } else {
            //otherwise 3 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 2);
          }
        } else {
          //if ordered after 10am it will ship tomorrow auto 4 days
          //skip sunday - 5 days
          if (day + 3 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 4);
          } else {
            //otherwise 3 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 3);
          }
        }

        //add shipdate to array
        this.shipDates.push({date: this.shipDate, price: "FREE"});
        //add overnight option
        //reset ship date to today
        this.shipDate = new Date();
        hour = this.shipDate.getHours();
        day = this.shipDate.getDay();
        //if ordered before 10am it will ship today
        if (hour < 10) {
          //skip sunday
          if (day + 1 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 2);
          } else {
            //otherwise 1 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 1);
          }
        } else {
          //if ordered after 10am it will ship tomorrow auto 4 days
          //skip sunday - 3 days
          if (day + 1 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 3);
          } else {
            //otherwise 2 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 2);
          }
        }

        //add shipdate to array
        this.shipDates.push({date: this.shipDate, price: "$57.86"});
      } else {
        //if not in USA

        //add first class option 12 days
        //reset ship date to today
        this.shipDate = new Date();
        hour = this.shipDate.getHours();
        day = this.shipDate.getDay();
        //if ordered before 10am it will ship today
        if (hour < 10) {
          //skip sunday
          if (day + 12 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 13);
          } else {
            //otherwise 12 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 12);
          }
        } else {
          //if ordered after 10am it will ship tomorrow auto 4 days
          //skip sunday - 3 days
          if (day + 12 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 14);
          } else {
            //otherwise 2 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 13);
          }
        }

        //add shipdate to array
        this.shipDates.push({date: this.shipDate, price: "$32.99"});

        //add fedex option 5 days
        //reset ship date to today
        this.shipDate = new Date();
        hour = this.shipDate.getHours();
        day = this.shipDate.getDay();
        //if ordered before 10am it will ship today
        if (hour < 10) {
          //skip sunday
          if (day + 5 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 6);
          } else {
            //otherwise 12 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 5);
          }
        } else {
          //if ordered after 10am it will ship tomorrow auto 4 days
          //skip sunday - 3 days
          if (day + 5 > 6 || day == 0) {
            this.shipDate.setDate(this.shipDate.getDate() + 7);
          } else {
            //otherwise 2 day shipping
            this.shipDate.setDate(this.shipDate.getDate() + 6);
          }
        }

        //add shipdate to array
        this.shipDates.push({date: this.shipDate, price: "$144.99"});
      }
      
    },
    getShipMonth: function (date) {
      const monthNames = [
        "Jan.",
        "Feb.",
        "Mar.",
        "Apr.",
        "May",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dec.",
      ];

      return monthNames[date.getMonth()];
    },
    getShipDay: function (date) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return days[date.getDay()];
    },
    getShippingCost: function() {
      if(this.shipDates[this.shipDateSelected].price == "FREE"){
        return 0.00;
      }else {
        return Number(this.shipDates[this.shipDateSelected].price.replace(/[^0-9.-]+/g,""));
      }
    },
    parsePictures: function () {
      for (var i in this.cart) {
        this.cart[i].picture = this.cart[i].picture.split(",");
      }
    },
    getQuantity: function () {
      var quantity = 0;
      for (var i in this.cart) {
        quantity += parseInt(this.cart[i].quantity);
      }
      return quantity;
    },
    getSubtotal: function () {
      var subtotal = 0;
      for (var i in this.cart) {
        subtotal += this.cart[i].quantity * this.cart[i].price;
      }
      return subtotal.toFixed(2);
    },
    getWeight: function (weight) {
      switch (weight) {
        case 1:
          return "1g";
        case 3.5:
          return "3.5g";
        case 7:
          return "7g";
        case 14:
          return "14g";
        case 28:
          return "1oz";
        case 112:
          return "4oz";
        case 224:
          return "8oz";
        case 448:
          return "1 pound";
      }
    },
    getImgURL: function (index) {
      return this.$hostname + "/uploads/" + this.cart[index].picture[0];
    },
    deleteCartItem: function (id, index) {
      axios
        .post(this.$hostname + "/api/cart/delete", { id: id })
        .then((response) => {
          if (response.data == true) {
            eventBus.$emit("atc", this.cart[index].quantity * -1);
            this.cart.splice(index, 1);
            if (this.cart.length == 0) {
              if (this.$props.prevRoute == "/checkout") {
                this.$router.push({ name: "Home" });
              }
              this.$router.push(this.$props.prevRoute);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateChangeArray: function (index, event) {
      var qty = event.target.value - this.cart[index].quantity;
      this.cart[index].quantity = event.target.value;
      eventBus.$emit("atc", qty);
      this.change[this.cart[index].id] = this.cart[index].quantity;
    },
    checkout: function () {
      //updatechange array
      axios
        .post(this.$hostname + "/api/cart/update", this.change)
        .then(() => {
          this.$router.push("/checkout");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeShippingAddress: function () {
      this.address = false;
      this.choosepayment = false;
      if (this.billing) {
        this.billing = false;
        this.payment = true;
      } else {
        this.payment = true;
      }
    },
    changePaymentMethod: function () {
      this.address = true;
      this.payment = false;
      this.choosepayment = true;
    },
    goToBilling: function () {
      this.choosepayment = false;
      this.payment = false;
      this.billing = true;
      if (this.billingAddresses.length == 0) {
        this.addBillingAddressPopup();
      }
    },
    closeShippingAddress: function () {
      this.address = true;
    },
    closePaymentMethod: function () {
      this.choosepayment = false;
      this.payment = true;
    },
    closeBilling: function () {
      this.billing = false;
      this.choosepayment = false;
      this.payment = true;
    },
    openEmptyPopup: function () {},
    addAddressPopup: function () {
      this.popup = true;
      this.newAddress = true;
    },
    editAddressPopup: function (id) {
      Object.assign(this.shippingAddressEdit, this.shippingAddresses[id]);
      this.shippingAddressSelected = id;
      this.popup = true;
      this.editAddress = true;
    },
    addBillingAddressPopup: function () {
      this.popup = true;
      this.newBillingAddress = true;
    },
    async addCardPopup() {
      const appId = "sq0idp-e7-MBUeRMF6QkQ8QQQ01NA";
      const locationId = "LW21TF9NPAJBM";
      const payments = window.Square.payments(appId, locationId);

      try {
        this.card = await this.initializeCard(payments);
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }
      this.popup = true;
      this.newCard = true;
    },
    async closePopup() {
      //if we are in the card popup then we need to destroy the card b/c red error persists at the bottom of page
      this.popup = false;
      this.newAddress = false;
      this.editAddress = false;

      this.newBillingAddress = false;
      if (this.newCard == true) {
        await this.card.destroy();
        this.newCard = false;
      } else {
        this.newCard = false;
      }
    },
    populateYears: function () {
      var today = new Date();
      var year = today.getFullYear();

      for (var i = 0; i < 20; i++) {
        this.years.push(year + i);
      }
    },
    useShippingAddress: function () {
      axios
        .post(this.$hostname + "/api/shipping/add", this.shippingAddress)
        .then((response) => {
          this.validateName(response.data.name);
          this.validateAddress(response.data.address1);
          this.validateCityStateZip(response.data);

          if (!this.loggedin) {
            //validate email
            this.validateEmail(response.data.email);
          } else {
            //dont need the email if we're logged in
            response.data.email = false;
          }

          for (var i in response.data) {
            if (response.data[i] == true) {
              return;
            }
          }
          //added address to database
          //goto next step.
          this.getShippingAddresses();
          this.changePaymentMethod();
          this.closePopup();
          if (this.cards.length == 0) {
            this.addCardPopup();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async updateShippingAddress() {
      axios
        .post(this.$hostname + "/api/shipping/update", this.shippingAddressEdit)
        .then((response) => {
          this.validateName(response.data.name);
          this.validateAddress(response.data.address1);
          this.validateCityStateZip(response.data);

          if (!this.loggedin) {
            //validate email
            this.validateEmail(response.data.email);
          } else {
            //dont need the email if we're logged in
            response.data.email = false;
          }

          for (var i in response.data) {
            if (response.data[i] == true) {
              return;
            }
          }
          //added address to database
          //goto next step.

          this.closePopup();
          //get shipping addresses without changing selected address
          axios
            .get(this.$hostname + "/api/shipping")
            .then((response) => {
              this.shippingAddresses = response.data;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    validateName: function (name) {
      this.err.shippingAddress.name = "";
      if (name == true) {
        this.err.shippingAddress.name = "First and last name is required";
      }
    },
    validateEmail: function (email) {
      this.err.shippingAddress.email = "";
      if (email == true) {
        this.err.shippingAddress.email = "Please enter a valid email address";
      }
    },
    validateAddress: function (address) {
      this.err.shippingAddress.address = "";
      if (address == true) {
        this.err.shippingAddress.address =
          "Please enter a valid street address";
      }
    },
    validateCityStateZip: function (data) {
      this.err.shippingAddress.city = "";
      this.err.shippingAddress.state = "";
      this.err.shippingAddress.zipcode = "";
      if (data.city == true) {
        this.err.shippingAddress.city = "City is required";
      }
      if (data.state == true) {
        this.err.shippingAddress.state = "Please select a state";
      }
      if (data.zipcode == true) {
        this.err.shippingAddress.zipcode = "Please enter a valid zipcode";
      }
    },
    getShippingAddresses: function () {
      axios
        .get(this.$hostname + "/api/shipping")
        .then((response) => {
          this.shippingAddresses = response.data;
          if (this.shippingAddresses.length > 0) {
            this.address = true;
            this.shippingAddressSelected = this.shippingAddresses.length - 1;
          } else {
            this.changeShippingAddress();
            this.addAddressPopup();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    useBillingAddress: function () {
      //add billing address to db
      axios
        .post(this.$hostname + "/api/billing/add", this.billingAddress)
        .then((response) => {
          this.validateBillingName(response.data.name);
          this.validateBillingAddress(response.data.address1);
          this.validateBillingCityStateZip(response.data);
          for (var i in response.data) {
            if (response.data[i] == true) {
              return;
            }
          }
          //attach billing address to card and close billing
          this.attachBillingToCard(response.data.insertId);
          //refresh billing addresses
          this.getBillingAddresses();
          //closePopup
          this.closePopup();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    validateBillingName: function (name) {
      this.err.billingAddress.name = "";
      if (name == true) {
        this.err.billingAddress.name = "First and last name is required";
      }
    },
    validateBillingAddress: function (address) {
      this.err.billingAddress.address = "";
      if (address == true) {
        this.err.billingAddress.address = "Please enter a valid street address";
      }
    },
    validateBillingCityStateZip: function (data) {
      this.err.billingAddress.city = "";
      this.err.billingAddress.state = "";
      this.err.billingAddress.zipcode = "";
      if (data.city == true) {
        this.err.billingAddress.city = "City is required";
      }
      if (data.state == true) {
        this.err.billingAddress.state = "Please select a state";
      }
      if (data.zipcode == true) {
        this.err.billingAddress.zipcode = "Please enter a valid zipcode";
      }
    },
    getBillingAddresses: function () {
      axios
        .get(this.$hostname + "/api/billing")
        .then((response) => {
          this.billingAddresses = response.data;
          this.billingAddressSelected = this.billingAddresses.length - 1;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    attachBillingToCard: function (id) {
      axios
        .post(this.$hostname + "/api/card/update/billing", {
          billingId: id,
          cardId: this.cards[this.cardSelected].id,
        })
        .then((response) => {
          if (response.data == true) {
            //refresh cards
            this.getCards();
            //close billing
            this.closeBilling();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    address2Format: function (address2) {
      if (address2 == "") {
        return "";
      } else {
        return ", " + address2;
      }
    },
    async checkYourCard() {
      //check the card input
      try {
        const result = await this.tokenizeCard();
        //proceed to billing or show errors
        if (result.status == "OK") {
          //new card is not error
          this.err.card = " ";
          //insert card into database
          this.addYourCard(result);
          //close and go to billing on success
          this.closePopup();
          this.goToBilling();
          if (this.billingAddresses.length == 0) {
            this.addBillingAddressPopup();
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    addYourCard: function (data) {
      axios
        .post(this.$hostname + "/api/card/add", data)
        .then((response) => {
          this.newCardId = response.data.insertId;
          this.getCards();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCards: function () {
      axios
        .get(this.$hostname + "/api/cards")
        .then((response) => {
          console.log(response.data);
          this.cards = response.data;

          if (this.cards.length > 0) {
            this.cardSelected = this.cards.length - 1;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    brandFormat: function () {
      var text = "";
      if (this.cardSelected.toString() != "") {
        text = this.cards[this.cardSelected].brand;
      }
      return text;
    },
    last4Format: function () {
      var text = "";

      if (this.cardSelected.toString() != "") {
        text = " ending in " + this.cards[this.cardSelected].last4;
      }
      return text;
    },
    getNameOnCardFormat: function (billingId) {
      for (var i in this.billingAddresses) {
        if (billingId == this.billingAddresses[i].id) {
          return this.billingAddresses[i].name;
        }
      }
    },
    createCryptoOrder: function () {
      //updatechange array
      axios.post(this.$hostname + "/api/cart/update", this.change).then(() => {
        //verify that there's a shipping address
        if (this.shippingAddresses.length == 0) {
          this.changeShippingAddress();
          this.addAddressPopup();
          return;
        }

        //create a now payments deposit address
        var data = JSON.stringify({
          price_amount: (
            (this.getSubtotal() -
              (this.getSubtotal() * parseFloat(this.promoCode.percent)) / 100) *
            1.0925 + parseFloat(this.getShippingCost())
          ).toFixed(2),
          price_currency: "usd",
          pay_currency:
            this.nowPaymentCurrencies[this.selectedNowPaymentCurrency],
          ipn_callback_url: "https://uscannabis.co/api/order/crypto/callback",
        });
        var config = {};
        Object.assign(config, this.nowPaymentConfig);
        config.method = "post";
        config.url = config.url + "payment";
        config.data = data;
        axios.defaults.withCredentials = false;
        axios(config)
          .then((response) => {
            axios.defaults.withCredentials = true;
            //show the address, amount, and expiration countdown
            this.nowPaymentId = response.data.payment_id;
            this.nowPaymentAddress = response.data.pay_address;
            this.nowPaymentEstimate = response.data.pay_amount;
            this.nowPaymentStatus = response.data.payment_status;
            this.duration = 60 * 60 * 24;
            //create an order with waiting for payment status
            var orderData = {
              cart: this.cart,
              price: (
                (this.getSubtotal() -
                  (this.getSubtotal() * parseFloat(this.promoCode.percent)) /
                    100) *
                1.0925 + parseFloat(this.getShippingCost())
              ).toFixed(2),
              shipping: this.shippingAddresses[this.shippingAddressSelected],
              deliveryDate: this.shipDate.valueOf(),
              payment: {
                id: response.data.payment_id,
                status: response.data.payment_status,
                approvedMoney: {
                  amount: response.data.pay_amount,
                },
              },
              billing: {
                id: 0,
              },
            };

            axios
              .post(this.$hostname + "/api/order/crypto", orderData)
              .then((response) => {
                this.getCryptoOrderStatus(response.data.insertId);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    placeYourOrder: function () {
      //updatechange array
      axios
        .post(this.$hostname + "/api/cart/update", this.change)
        .then(() => {
          var data = {
            card: this.cards[this.cardSelected],
            billing: this.billingAddresses[this.billingAddressSelected],
            price: (
              (this.getSubtotal() -
                (this.getSubtotal() * parseFloat(this.promoCode.percent)) /
                  100) *
              1.0925 + parseFloat(this.getShippingCost())
            ).toFixed(2),
            cart: this.cart,
            shipping: this.shippingAddresses[this.shippingAddressSelected],
            deliveryDate: this.shipDate.valueOf(),
          };
          //verify that the data is correct
          //1. Verify Shipping Address
          if (this.shippingAddresses.length == 0) {
            this.changeShippingAddress();
            this.addAddressPopup();
            return;
          }
          //2. Verify Payment
          if (this.cards.length == 0) {
            this.changePaymentMethod();
            this.addCardPopup();
            return;
          }
          //3. Verify Billing Address
          if (this.cards[this.cardSelected].billingId == null) {
            this.closeShippingAddress();
            this.goToBilling();
            if (this.billingAddresses.length == 0) {
              this.addBillingAddressPopup();
            }
            return;
          }
          //try to create a payment with info on file
          axios
            .post(this.$hostname + "/api/order/pay", data)
            .then((response) => {
              //try to create a payment with info on file
              if (response.data.affectedRows == 1) {
                //order paid
                //clear cart
                this.clearCart();
                //go to thanks page
                this.$router.push({
                  name: "Home",
                  params: { order: response.data.insertId },
                });
              } else if (response.data == "Card_Invalid") {
                //card was declined
                //open card and show errors
                this.err.card =
                  "Your have entered an invalid card. Please enter a new payment method.";
                
                this.changePaymentMethod();
                this.addCardPopup().then(() => {
                    this.card.focus("cardNumber");
                });
                
              } 
              else if (response.data == "DECLINED") {
                //card was declined
                //open card and show errors
                this.err.card =
                  "Your card was declined. Please enter a new payment method.";
                
                this.changePaymentMethod();
                this.addCardPopup().then(() => {
                    this.card.focus("cardNumber");
                });
                
              } else if (response.data == "CVV_FAILURE") {
                this.err.card = "Invalid CVV";
                
                this.changePaymentMethod();
                this.addCardPopup().then(() => {
                    this.card.focus("cvv");
                });
                
              } else if (response.data == "ADDRESS_VERIFICATION_FAILURE") {
                this.err.card = "Invalid postal code";
                
                this.changePaymentMethod();
                this.addCardPopup().then(() => {
                    this.card.focus("postalCode");
                });
                
              } else if (response.data == "INVALID_EXPIRATION") {
                this.err.card = "Invalid expiration date";
                
                this.changePaymentMethod();
                this.addCardPopup().then(() => {
                    this.card.focus("expirationDate");
                });
                
              } else {
                this.err.card =
                  "Your card was declined. Please enter a new payment method.";
                
                this.changePaymentMethod();
                this.addCardPopup().then(() => {
                    this.card.focus("cardNumber");
                });
                
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    clearCart: function () {
      for (var i in this.cart) {
        eventBus.$emit("atc", this.cart[i].quantity * -1);
      }
      this.cart = [];
      axios
        .get(this.$hostname + "/api/cart/clear")
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    },
    applyPromoCode: function () {
      this.promoCodeErr = "";
      axios
        .post(this.$hostname + "/api/promo/find", this.promoCode)
        .then((response) => {
          if (response.data.length > 0) {
            this.promoCode = response.data[0];
          } else {
            this.promoCodeErr =
              "The promotional code you entered is not valid.";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateCart: function (next) {
      axios
        .post(this.$hostname + "/api/cart/update", this.change)
        .then(() => {
          next();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    selectShippingAddress: function (id) {
      this.shippingAddressSelected = id;
    },
    selectBillingAddress: function (id) {
      this.billingAddressSelected = id;
    },
    selectCard: function (id) {
      this.cardSelected = id;
    },
    countdown: function () {
      setInterval(() => {
        if (this.duration > 0) {
          let hours = parseInt((this.duration / 3600) % 24, 10);
          let minutes = parseInt((this.duration / 60) % 60, 10);
          let seconds = parseInt(this.duration % 60, 10);

          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.timer = hours + ":" + minutes + ":" + seconds;

          --this.duration;
        }
      }, 1000);
    },
    getCryptoOrderStatus: function (insertId) {
      setInterval(() => {
        axios
          .post(this.$hostname + "/api/order/crypto/status", {
            payment_id: this.nowPaymentId,
          })
          .then((response) => {
            console.log(insertId);
            this.nowPaymentStatus = response.data[0].paymentStatus;
            if (response.data[0].paymentStatus == "finished") {
              this.clearCart();
              //go to thanks page
              this.$router.push({ name: "Home", params: { order: insertId } });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }, 30000);
    },
  },

  computed: {

  },
  watch: {
    shippingAddressSelected: function(){
      this.updateShipDate();
    },
    shippingAddresses: function(){
      this.updateShipDate();
    },
  },
};
</script>

<style lang="scss" scoped>
$green: #417e3e;

@media (min-width: 768px) {
  #card-container {
    width: 100%;
  }
  .green {
    color: $green;
    font-size: 14px;
  }
  .container {
    padding: 2% 10%;
    display: flex;
  }
  .checkoutInfo {
    flex: 8;
    .step {
      flex: 1;
    }
    .header {
      flex: 3;
      h2 {
        text-align: left;
      }
    }
    .address {
      flex: 8;
    }
    .change {
      display: flex;
      align-items: center;
      flex: 1;
      a {
        text-decoration: none;
        color: $green;
        font-size: 14px;
        cursor: pointer;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .shippingcontainer {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #eeeeee;
      color: $green;
      .address {
        text-align: left;
        font-size: 12px;
        color: #2c3e50;
        padding-top: 1%;
        p {
          width: 60%;
          margin-left: 40%;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    .shippingaddresscontainer-container {
      border-bottom: 1px solid #eeeeee;
      .addresses {
        padding: 0 5%;

        width: 90%;
        .heading {
          width: 100%;
          color: $green;
          border-bottom: 1px solid #eeeeee;
          h2 {
            margin-bottom: 1%;
          }
        }
        h2 {
          text-align: left;
          font-size: 1.4em;
        }
        .alladdresses {
          padding-top: 2%;
        }
        .address {
          cursor: pointer;
          display: flex;
          text-align: left;
          padding-bottom: 2%;
          label {
            cursor: pointer;
          }
          .input {
            flex: 8;
            span {
              font-weight: bold;
            }
          }
          .edit {
            flex: 2;
            text-align: right;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 14px;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
        .addAddress {
          text-align: left;
          padding-top: 2%;
          padding-bottom: 3%;
          span {
            font-size: 1.5em;
            color: $green;
            text-align: left;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 0.7em;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .shippingaddresscontainer {
      display: flex;
      width: 100%;

      color: $green;
      .header {
        flex: 11;
      }
    }
    .paymentcontainer-container {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 2%;
    }
    .paymentcontainer {
      display: flex;
      width: 100%;

      color: $green;
      h2 {
        margin-bottom: 0;
      }
      .address {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 12px;
        color: #2c3e50;
        .err {
          color: #ff0033;
        }
        p {
          width: 60%;
          margin-left: 40%;
          margin-top: 0;
          margin-bottom: 0;
          span {
            font-weight: bold;
          }
        }
        input {
          width: 20%;
          margin-left: 40%;
          margin-top: 0;
          margin-bottom: 0;
          border: 1px solid #e5e7e7;
          border-right: 0px;
          padding: 8px 5px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        input:focus {
          outline: 0px;
        }
      }
    }
    .choosepaymentcontainer {
      width: 100%;
      border-bottom: 1px solid #eeeeee;

      .choosepaymentheadingcontainer {
        display: flex;
        width: 100%;

        color: $green;
        .header {
          flex: 11;
        }
      }
      .header {
        flex: 11;
      }
      .addresses {
        padding: 0 5%;

        width: 90%;
        .heading {
          width: 100%;
          color: $green;

          h2 {
            margin-bottom: 1%;
          }
        }
        .subheading {
          display: flex;
          border-bottom: 1px solid #eeeeee;
          font-size: 14px;
          color: #888;
          p {
            flex: 2;
            margin-top: 0;
          }
          p:nth-of-type(2) {
            text-align: left;
          }
          p:nth-of-type(3) {
            text-align: right;
          }
          .flex8 {
            flex: 8;
            text-align: left;
          }
        }
        h2 {
          text-align: left;
          font-size: 1.4em;
        }
        .alladdresses {
          padding-top: 2%;
        }
        .address {
          cursor: pointer;
          display: flex;
          text-align: left;
          label {
            cursor: pointer;
          }
          .input {
            flex: 8;
            span {
              font-weight: bold;
            }
            p {
              text-decoration: none;
              font-size: 14px;
              margin: 0;
            }
          }
          .name,
          .exp {
            flex: 2;

            p {
              text-decoration: none;
              font-size: 14px;
              margin: 0;
            }
          }
          .name {
            text-align: left;
          }
          .exp {
            text-align: right;
          }
        }
        .addAddress {
          text-align: left;
          padding-top: 2%;
          padding-bottom: 3%;
          .green {
            font-size: 14px;
          }
          span {
            font-size: 1.5em;
            color: $green;
            text-align: left;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 0.7em;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .billingaddresscontainer {
      border-bottom: 1px solid #eeeeee;
      .addresses {
        padding: 0 5%;

        width: 90%;
        .heading {
          width: 100%;
          color: $green;
          border-bottom: 1px solid #eeeeee;
          h2 {
            margin-bottom: 1%;
          }
        }
        h2 {
          text-align: left;
          font-size: 1.4em;
        }
        .alladdresses {
          padding-top: 2%;
          padding-bottom: 2%;
        }
        .address {
          cursor: pointer;
          display: flex;
          text-align: left;
          padding: 1% 0;
          label {
            cursor: pointer;
          }
          .input {
            flex: 8;
            span {
              font-weight: bold;
            }
          }
          .edit {
            flex: 2;
            text-align: right;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 14px;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
        .addAddress {
          text-align: left;
          padding-top: 2%;
          padding-bottom: 3%;
          span {
            font-size: 1.5em;
            color: $green;
            text-align: left;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 0.7em;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .reviewcontainer {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #eeeeee;
      color: $green;
      .header {
        flex: 12;
      }
    }
    .itemscontainer {
      text-align: left;
      padding: 0 10%;
      p {
        margin-top: 0;
        color: #555;
        font-size: 14px;
      }
      h3 {
        margin-bottom: 0;
      }
      .same-shipping {
        display: flex;
        .allItems {
          flex: 2;
        }
        .shipping {
          flex: 1;
          .heading {
            font-weight: bold;
          }
          label {
            color: $green;
            font-weight: bold;
          }
          .shipping-cost {
            margin-left: 21px;
            font-size: 12px;
            margin-top: 1%;
          }
        }
      }
      .items {
        display: flex;
        padding-bottom: 3%;
        margin-bottom: 3%;
        .image {
          flex: 1;
          img {
            width: 100%;
          }
        }
        .item {
          flex: 4;
          padding: 0 5%;
          h3 {
            margin-top: 0;
            color: $green;
            span {
              color: #2c3e50;
              font-size: 12px;
              font-weight: normal;
            }
          }
          .options {
            padding-top: 2%;
            display: flex;
            align-items: center;
            color: #2c3e50;
            font-size: 14px;
            input {
              margin-left: 1%;
              width: 6%;
              font-size: 14px;
            }
            p {
              margin: 0;
              margin-left: 2%;
              margin-right: 2%;
              font-size: 16px;
            }
            a {
              font-size: 12px;
              text-decoration: none;
              cursor: pointer;
              color: $green;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .placeOrderContainer {
    flex: 3;
    padding: 0 5%;
    .checkoutBtn {
      box-shadow: 0px 10px 14px -7px #3e7327;
      background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
      background-color: #417e3e;
      border-radius: 4px;
      border: 1px solid #4b8f29;
      display: inline-block;
      cursor: pointer;
      color: #ffffff;
      font-family: Arial;
      font-size: 13px;
      font-weight: bold;
      padding: 10px 28px;
      text-decoration: none;
      text-shadow: 0px 1px 0px #5b8a3c;
      width: 100%;
    }
    .checkoutBtn:hover {
      background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
      background-color: #346431;
    }
    .checkoutBtn:active {
      position: relative;
      top: 1px;
    }

    p {
      padding-top: 4%;
      font-size: 10px;
    }
    .orderTotal {
      border-top: 1px solid #eeeeee;
      padding: 0 2%;
      h2 {
        text-align: left;
      }
      .table-item {
        display: flex;

        .left {
          flex: 1;
          justify-content: left;
          text-align: left;
          font-size: 12px;
          margin: 0;
        }
        .right {
          flex: 1;
          justify-content: right;
          text-align: right;
          font-size: 12px;
          margin: 0;
        }
        .big {
          font-size: 1.5em;
        }
      }

      .tally {
        display: flex;
        p {
          flex: 5;
          margin-bottom: 0;
          padding: 0;
        }
        .underline {
          flex: 1;
          border-top: 1px solid #eeeeee;
        }
      }

      .padded-top {
        padding-top: 5%;
      }
    }
  }

  .dark-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;

    .close {
      float: right;
      margin-right: 2%;
      margin-top: 1%;
      color: #346431;
    }
    .login-container {
      position: fixed;
      width: 40%;
      left: 50%;
      top: 50%;
      height: auto;
      background-color: white;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
    .logo {
      width: 40%;
      padding-bottom: 0%;
    }
    .input-container {
      padding: 5%;
      padding-top: 5%;
      padding-bottom: 10%;
      width: 80%;
      margin-left: 5%;
      .cardContainer {
        .err {
          font-size: 12px;
          margin-top: -5%;
        }
      }
      .err {
        color: #ff0033;
        font-size: 10px;
        font-weight: 400;
      }
      .register {
        font-weight: bold;
        font-size: 12px;
        margin-top: 5px;
        text-align: center;
      }
      .forgot {
        font-weight: bold;
        font-size: 12px;
        margin-top: 5px;
        text-align: left;
        cursor: pointer;
      }
      .inputs {
        padding-top: 0;
      }
      div {
        padding-top: 3%;
        width: 100%;
        select {
          display: block;
          padding: 0.5% 0;
        }
        input {
          margin: 0.75% 0;
          padding: 0.5% 0;

          display: block;
        }
      }
      p {
        text-align: left;
        margin: 0;
        width: 100%;
        font-weight: bold;
        color: #346431;
        cursor: pointer;
      }
      input {
        width: 100%;
      }
      .citystatezip {
        display: flex;
        div:nth-of-type(1) {
          padding-left: 0;
          flex: 10;
        }
        div:nth-of-type(3) {
          padding-right: 0;
        }
        div {
          flex: 8;
          padding: 0 2%;
          select {
            width: 100%;
          }
        }
      }
      .expInput {
        display: flex;
        select:nth-of-type(2) {
          margin-left: 2%;
        }
      }

      .btnContainer {
        display: flex;
        justify-content: center;
      }
      .loginBtn {
        box-shadow: 0px 10px 14px -7px #3e7327;
        background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
        background-color: #417e3e;
        border-radius: 4px;
        border: 1px solid #4b8f29;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        padding: 10px 28px;
        text-decoration: none;
        text-shadow: 0px 1px 0px #5b8a3c;
      }
      .loginBtn:hover {
        background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
        background-color: #346431;
      }
      .loginBtn:active {
        position: relative;
        top: 1px;
      }
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }

  .apply {
    box-shadow: 0px 10px 14px -7px #eeeeee;

    background-color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #e5e7e7;
    display: inline-block;
    cursor: pointer;
    color: #2c3e50;
    font-family: Arial;
    font-size: 13px;
    font-weight: normal;
    padding: 8px 10px;
    text-decoration: none;
  }
  .apply:hover {
    background-color: #f7fafa;
  }
  .apply:active {
    position: relative;
    top: 1px;
  }
}

@media (max-width: 767px) {
  .displayNone {
    display: none;
  }
  #card-container {
    width: 100%;
  }

  .container {
    padding: 2% 5%;
  }
  .checkoutInfo {
    .step {
      flex: 2;
    }
    .header {
      flex: 3;
      h2 {
        text-align: left;
      }
    }
    .address {
      flex: 8;
    }
    .change {
      display: flex;
      align-items: center;
      flex: 1;
      a {
        text-decoration: none;
        color: $green;
        font-size: 14px;
        cursor: pointer;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .shippingcontainer {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #eeeeee;
      color: $green;
      .address {
        text-align: left;
        font-size: 12px;
        color: #2c3e50;
        padding-top: 5%;
        p {
          width: 80%;
          margin-left: 10%;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    .shippingaddresscontainer-container {
      border-bottom: 1px solid #eeeeee;
      .addresses {
        padding: 0 5%;

        width: 90%;
        .heading {
          width: 100%;
          color: $green;
          border-bottom: 1px solid #eeeeee;
          h2 {
            margin-bottom: 1%;
          }
        }
        h2 {
          text-align: left;
          font-size: 1.4em;
        }
        .alladdresses {
          padding-top: 2%;
        }
        .address {
          cursor: pointer;
          display: flex;
          text-align: left;
          padding-bottom: 2%;
          label {
            cursor: pointer;
          }
          .input {
            flex: 8;
            span {
              font-weight: bold;
            }
          }
          .edit {
            flex: 2;
            text-align: right;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 14px;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
        .addAddress {
          text-align: left;
          padding-top: 2%;
          padding-bottom: 3%;
          span {
            font-size: 1.5em;
            color: $green;
            text-align: left;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 0.7em;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .shippingaddresscontainer {
      display: flex;
      width: 100%;

      color: $green;
      .header {
        flex: 11;
      }
    }
    .paymentcontainer-container {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 2%;
    }
    .paymentcontainer {
      display: flex;
      width: 100%;

      color: $green;
      h2 {
        margin-bottom: 0;
      }
      .address {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 12px;
        color: #2c3e50;
        .err {
          color: #ff0033;
        }
        p {
          width: 80%;
          margin-left: 10%;
          margin-top: 0;
          margin-bottom: 0;
          span {
            font-weight: bold;
          }
        }
        input {
          width: 80%;
          margin-left: 10%;
          margin-top: 0;
          margin-right: 0;
          margin-bottom: 0;
          border: 1px solid #e5e7e7;
          border-right: 0px;
          padding: 8px 6px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        input:focus {
          outline: 0px;
        }
        button {
          margin-left: 0px;
        }
      }
    }
    .choosepaymentcontainer {
      width: 100%;
      border-bottom: 1px solid #eeeeee;

      .choosepaymentheadingcontainer {
        display: flex;
        width: 100%;

        color: $green;
        .header {
          flex: 11;
        }
      }
      .header {
        flex: 11;
      }
      .addresses {
        padding: 0 5%;

        width: 90%;
        .heading {
          width: 100%;
          color: $green;

          h2 {
            margin-bottom: 1%;
          }
        }
        .subheading {
          display: flex;
          border-bottom: 1px solid #eeeeee;
          font-size: 14px;
          color: #888;
          p {
            flex: 2;
            margin-top: 0;
          }
          p:nth-of-type(2) {
            text-align: left;
          }
          p:nth-of-type(3) {
            text-align: right;
          }
          .flex8 {
            flex: 8;
            text-align: left;
          }
        }
        h2 {
          text-align: left;
          font-size: 1.4em;
        }
        .alladdresses {
          padding-top: 2%;
        }
        .address {
          cursor: pointer;
          display: flex;
          text-align: left;
          label {
            cursor: pointer;
          }
          .input {
            flex: 8;
            span {
              font-weight: bold;
            }
          }
          .name,
          .exp {
            flex: 2;

            p {
              text-decoration: none;
              font-size: 14px;
              margin: 0;
            }
          }
          .name {
            text-align: left;
          }
          .exp {
            text-align: right;
          }
        }
        .addAddress {
          text-align: left;
          padding-top: 2%;
          padding-bottom: 3%;
          .green {
            font-size: 1em;
          }
          p {
            font-size: 1em;
          }
          span {
            font-size: 1em;
            color: $green;
            text-align: left;

            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 0.7em;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .billingaddresscontainer {
      border-bottom: 1px solid #eeeeee;
      .addresses {
        padding: 0 5%;

        width: 90%;
        .heading {
          width: 100%;
          color: $green;
          border-bottom: 1px solid #eeeeee;
          h2 {
            margin-bottom: 1%;
          }
        }
        h2 {
          text-align: left;
          font-size: 1.4em;
        }
        .alladdresses {
          padding-top: 2%;
          padding-bottom: 2%;
        }
        .address {
          cursor: pointer;
          display: flex;
          text-align: left;
          padding: 1% 0;
          label {
            cursor: pointer;
          }
          .input {
            flex: 8;
            span {
              font-weight: bold;
            }
          }
          .edit {
            flex: 2;
            text-align: right;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 14px;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
        .addAddress {
          text-align: left;
          padding-top: 2%;
          padding-bottom: 3%;
          span {
            font-size: 1.5em;
            color: $green;
            text-align: left;
            a {
              color: $green;
              text-decoration: none;
              cursor: pointer;
              font-size: 0.7em;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .reviewcontainer {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #eeeeee;
      color: $green;
      padding-bottom: 10%;
      .header {
        flex: 12;
      }
    }
    .itemscontainer {
      text-align: left;
      padding: 0 10%;
      p {
        margin-top: 0;
        color: #555;
        font-size: 14px;
      }
      h3 {
        margin-bottom: 0;
      }
      .same-shipping {
        .allItems {
        }
        .shipping {
          .heading {
            font-weight: bold;
          }
          label {
            color: $green;
            font-weight: bold;
          }
          .shipping-cost {
            margin-left: 21px;
            font-size: 12px;
            margin-top: 1%;
          }
        }
      }
      .items {
        display: flex;
        padding-bottom: 3%;
        margin-bottom: 3%;
        .image {
          flex: 1;
          img {
            width: 100%;
          }
        }
        .item {
          flex: 4;
          padding: 0 5%;
          h3 {
            margin-top: 0;
            color: $green;
            span {
              color: #2c3e50;
              font-size: 12px;
              font-weight: normal;
            }
          }
          .options {
            padding-top: 2%;
            display: flex;
            align-items: center;
            color: #2c3e50;
            font-size: 14px;
            input {
              margin-left: 1%;
              width: 12%;
              font-size: 14px;
            }
            p {
              margin: 0;
              margin-left: 2%;
              margin-right: 2%;
              font-size: 16px;
            }
            a {
              font-size: 12px;
              text-decoration: none;
              cursor: pointer;
              color: $green;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .placeOrderContainer {
    border-top: 1px solid #eeeeee;
    padding: 10% 5%;
    .checkoutBtn {
      box-shadow: 0px 10px 14px -7px #3e7327;
      background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
      background-color: #417e3e;
      border-radius: 4px;
      border: 1px solid #4b8f29;
      display: inline-block;
      cursor: pointer;
      color: #ffffff;
      font-family: Arial;
      font-size: 13px;
      font-weight: bold;
      padding: 10px 28px;
      text-decoration: none;
      text-shadow: 0px 1px 0px #5b8a3c;
      width: 100%;
    }
    .checkoutBtn:hover {
      background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
      background-color: #346431;
    }
    .checkoutBtn:active {
      position: relative;
      top: 1px;
    }

    p {
      padding-top: 4%;
      font-size: 10px;
    }
    .orderTotal {
      border-top: 1px solid #eeeeee;
      padding: 0 2%;
      h2 {
        text-align: left;
      }
      .table-item {
        display: flex;

        .left {
          flex: 1;
          justify-content: left;
          text-align: left;
          font-size: 12px;
          margin: 0;
        }
        .right {
          flex: 1;
          justify-content: right;
          text-align: right;
          font-size: 12px;
          margin: 0;
        }
        .big {
          font-size: 1.5em;
        }
      }

      .tally {
        display: flex;
        p {
          flex: 5;
          margin-bottom: 0;
          padding: 0;
        }
        .underline {
          flex: 1;
          border-top: 1px solid #eeeeee;
        }
      }

      .padded-top {
        padding-top: 5%;
      }
    }
  }

  .dark-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;

    .close {
      float: right;
      margin-right: 2%;
      margin-top: 1%;
      color: #346431;
    }
    .login-container {
      position: fixed;
      width: 100%;
      left: 50%;
      top: 50%;
      height: auto;
      background-color: white;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
    .logo {
      width: 40%;
      padding-bottom: 0%;
    }
    .input-container {
      padding: 5%;
      padding-top: 5%;
      padding-bottom: 10%;
      width: 80%;
      margin-left: 5%;
      .cardContainer {
        .err {
          font-size: 12px;
          margin-top: -5%;
        }
      }
      .err {
        color: #ff0033;
        font-size: 10px;
        font-weight: 400;
      }
      .register {
        font-weight: bold;
        font-size: 12px;
        margin-top: 5px;
        text-align: center;
      }
      .forgot {
        font-weight: bold;
        font-size: 12px;
        margin-top: 5px;
        text-align: left;
        cursor: pointer;
      }
      .inputs {
        padding-top: 0;
      }
      div {
        padding-top: 3%;
        width: 100%;
        select {
          display: block;
          padding: 0.5% 0;
          line-height: 2em;
          color: #2c3e50;
        }
        input {
          margin: 0.75% 0;
          padding: 0.5% 0;

          display: block;
        }
      }
      p {
        text-align: left;
        margin: 0;
        width: 100%;
        font-weight: normal;
        font-size: 1.05em;
        color: #346431;
        cursor: pointer;
      }
      input {
        width: 100%;
        line-height: 2em;
      }
      .citystatezip {
        display: flex;
        div:nth-of-type(1) {
          padding-left: 0;
          flex: 10;
        }
        div:nth-of-type(3) {
          padding-right: 0;
        }
        div {
          flex: 8;
          padding: 0 2%;
          select {
            width: 100%;
          }
        }
      }
      .expInput {
        display: flex;
        select:nth-of-type(2) {
          margin-left: 2%;
        }
      }

      .btnContainer {
        display: flex;
        justify-content: center;
      }
      .loginBtn {
        box-shadow: 0px 10px 14px -7px #3e7327;
        background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
        background-color: #417e3e;
        border-radius: 4px;
        border: 1px solid #4b8f29;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        padding: 10px 28px;
        text-decoration: none;
        text-shadow: 0px 1px 0px #5b8a3c;
      }
      .loginBtn:hover {
        background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
        background-color: #346431;
      }
      .loginBtn:active {
        position: relative;
        top: 1px;
      }
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }

  .apply {
    box-shadow: 0px 10px 14px -7px #eeeeee;

    background-color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #e5e7e7;
    display: inline-block;
    cursor: pointer;
    color: #2c3e50;
    font-family: Arial;
    font-size: 13px;
    font-weight: normal;
    padding: 8px 10px;
    text-decoration: none;
  }
  .apply:hover {
    background-color: #f7fafa;
  }
  .apply:active {
    position: relative;
    top: 1px;
  }
}
</style>
