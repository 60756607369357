<template>
  <ProductDetails :productName="$route.params.product" />
</template>

<script>
// @ is an alias to /src
import ProductDetails from "@/components/ProductDetails.vue";

export default {
  name: "Shop",
  props: {},
  components: {
    ProductDetails,
  },
};
</script>
