<template>
  <div>
    <div class="links">
      <a
        href="#"
        v-for="(link, index) in links"
        v-bind:key="index"
        v-on:click="run(link)"
        >{{ link }}</a
      >
    </div>
    <AdminCreate v-if="showCreateAdmin" />
    <AdminEditPrivileges v-if="showEditPrivileges" />
    <AdminPriceTiers v-if="showPriceTiers" />
    <AdminEditProducts v-if="showProducts" />
    <AdminNewOrders v-if="showNewOrders" />
    <AdminShippedOrders v-if="showShippedOrders" />
    <AdminAddOrders v-if="showAddOrders" />
    <AdminPromoCodes v-if="showPromoCodes" />
  </div>
</template>
<script>
import AdminCreate from "@/components/AdminCreate.vue";
import AdminEditPrivileges from "@/components/AdminEditPrivileges.vue";
import AdminPriceTiers from "@/components/AdminPriceTiers.vue";
import AdminEditProducts from "@/components/AdminEditProducts.vue";
import AdminNewOrders from "@/components/AdminNewOrders.vue";
import AdminShippedOrders from "@/components/AdminShippedOrders.vue";
import AdminAddOrders from "@/components/AdminAddOrders.vue";
import AdminPromoCodes from "@/components/AdminPromoCodes.vue";
import axios from "axios";
export default {
  name: "AdminHeader",
  props: {},
  components: {
    AdminCreate,
    AdminEditPrivileges,
    AdminPriceTiers,
    AdminEditProducts,
    AdminNewOrders,
    AdminShippedOrders,
    AdminAddOrders,
    AdminPromoCodes,
  },
  data: function () {
    return {
      links: [],
      showCreateAdmin: false,
      showEditPrivileges: false,
      showPriceTiers: false,
      showProducts: false,
      showNewOrders: false,
      showShippedOrders: false,
      showAddOrders: false,
      showPromoCodes: false,
    };
  },
  methods: {
    getLinks: function () {
      axios
        .get(this.$hostname + "/api/admin/links")
        .then((response) => {
          this.links = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    run: function (link) {
      switch (link) {
        case "Price Tiers":
          this.priceTiers();
          break;
        case "Products":
          this.products();
          break;
        case "View Orders":
          this.newOrders();
          break;
        case "Add Orders":
          this.addOrders();
          break;
        case "Order History":
          this.shippedOrders();
          break;
        case "Create Admin":
          this.createAdmin();
          break;
        case "Edit Privileges":
          this.editPrivileges();
          break;
        case "Promo Codes":
          this.promoCodes();
          break;
      }
    },
    promoCodes: function () {
      this.closeAllTabs();
      this.showPromoCodes = true;
    },
    addOrders: function () {
      this.closeAllTabs();
      this.showAddOrders = true;
    },
    newOrders: function () {
      this.closeAllTabs();
      this.showNewOrders = true;
    },
    shippedOrders: function () {
      this.closeAllTabs();
      this.showShippedOrders = true;
    },
    priceTiers: function () {
      this.closeAllTabs();
      this.showPriceTiers = true;
    },
    createAdmin: function () {
      this.closeAllTabs();
      this.showCreateAdmin = true;
    },
    editPrivileges: function () {
      this.closeAllTabs();
      this.showEditPrivileges = true;
    },
    products: function () {
      this.closeAllTabs();
      this.showProducts = true;
    },
    closeAllTabs: function () {
      this.showCreateAdmin = false;
      this.showEditPrivileges = false;
      this.showPriceTiers = false;
      this.showProducts = false;
      this.showNewOrders = false;
      this.showShippedOrders = false;
      this.showAddOrders = false;
      this.showPromoCodes = false;
    },
  },
  mounted: function () {
    this.getLinks();
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .links {
    padding: 2.5%;
    display: flex;
    justify-content: space-around;
    a {
      text-decoration: none;
      color: #417e3e;
    }
  }
}
@media (max-width: 767px) {
  .links {
    padding: 2.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    a {
      line-height: 3em;
      text-decoration: none;
      color: #417e3e;
    }
  }
}
</style>
