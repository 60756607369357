<template>
  <PictureBox />
  <ShopCards :scroll="1" :subcategory="$route.params.subcategory" />
</template>

<script>
// @ is an alias to /src
import PictureBox from "@/components/PictureBox.vue";
import ShopCards from "@/components/ShopCards.vue";

export default {
  name: "Subcategory",
  props: {},
  components: {
    PictureBox,
    ShopCards,
  },
};
</script>
