<template>
  <div class="loginContainer">
    <div>
      <h1 class="title">Create Admin</h1>
    </div>
    <div class="inputContainer">
      <input v-model="admin.email" type="text" placeholder="Email" />
      <p class="err">{{ emailErrorMsg }}</p>
    </div>
    <div class="inputContainer">
      <input v-model="admin.password" type="password" placeholder="Password" />
      <p class="err">{{ passwordErrorMsg }}</p>
    </div>
    <div class="inputContainer">
      <input
        v-model="admin.confirmPassword"
        type="password"
        placeholder="Confirm Password"
      />
      <p class="err">{{ confirmPasswordErrorMsg }}</p>
    </div>
    <div class="inputContainer radio">
      <div v-for="(link, index) in links" v-bind:key="index">
        <input
          v-model="admin[camelCase(link)]"
          type="checkbox"
          :id="index"
          :name="index"
          :value="link"
        />
        <label :for="index">{{ link }}</label>
      </div>
    </div>
    <div class="btnContainer">
      <button v-on:click="createAdmin()" class="loginBtn">Create</button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminCreate",
  props: {},
  data: function () {
    return {
      links: [],
      admin: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      err: {
        email: {
          valid: "Please enter a valid email address",
          exists: "This email address already exists",
        },
        password: {
          length: "Password must be greater than 8 characters",
        },
        confirmPassword: {
          matches: "Password doesn't match",
        },
      },
      emailErrorMsg: "",
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
    };
  },
  methods: {
    getLinks: function () {
      axios
        .get(this.$hostname + "/api/admin/links")
        .then((response) => {
          this.links = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    createAdmin: function () {
      this.clearErrorMsg();
      axios
        .post(this.$hostname + "/api/admin/create", this.admin)
        .then((response) => {
          if (!response.data) this.$router.go();
          if (
            this.validateEmail(response.data.email) &&
            this.validatePassword(response.data.password) &&
            this.validateConfirmPassword(response.data.confirmPassword)
          ) {
            this.$router.go();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    clearErrorMsg: function () {
      this.emailErrorMsg = "";
      this.passwordErrorMsg = "";
      this.confirmPasswordErrorMsg = "";
    },
    validateEmail: function (data) {
      this.emailError = "";
      for (const property in data) {
        if (!data[property]) {
          this.emailErrorMsg = this.err.email[property];
          return false;
        }
      }
      return true;
    },
    validatePassword: function (data) {
      this.passwordError = "";
      for (const property in data) {
        if (!data[property]) {
          this.passwordErrorMsg = this.err.password[property];
          return false;
        }
      }
      return true;
    },
    validateConfirmPassword: function (data) {
      this.confirmPasswordError = "";
      for (const property in data) {
        if (!data[property]) {
          this.confirmPasswordErrorMsg = this.err.confirmPassword[property];
          return false;
        }
      }
      return true;
    },
    camelCase: function (s) {
      s = s.replace(/\s/g, "");
      s = s.charAt(0).toLowerCase() + s.slice(1);
      return s;
    },
  },
  mounted: function () {
    this.getLinks();
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .loginContainer {
    margin-top: 3%;

    width: 40%;
    margin-left: 30%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    width: 50%;
    margin-left: 25%;
    input {
      height: 25px;
      width: 100%;
    }
  }
  .radio {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 2%;
    }
    input {
      height: 1.2em;
    }
    label {
      font-size: 14px;
    }
  }
  .btnContainer {
    padding: 5%;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .loginContainer {
    margin-top: 3%;

    width: 100%;
    margin-left: 0%;
    padding-top: 2%;
    padding-bottom: 7.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .title {
    color: #417e3e;
  }
  .inputContainer {
    padding-bottom: 1%;
    padding-top: 1%;
    width: 50%;
    margin-left: 25%;
    input {
      height: 25px;
      width: 100%;
    }
  }
  .radio {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 2%;
    }
    input {
      height: 1.2em;
    }
    label {
      font-size: 14px;
    }
  }
  .btnContainer {
    padding: 5%;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
  .err {
    color: #ff0033;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
  }
}
</style>
