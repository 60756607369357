<template>
  <CheckoutForm :prevRoute="prevRoute" ref="checkoutform" />
</template>

<script>
// @ is an alias to /src
import CheckoutForm from "@/components/CheckoutForm.vue";

export default {
  name: "Checkout",
  components: {
    CheckoutForm,
  },
  data() {
    return {
      prevRoute: null,
    };
  },
  beforeRouteLeave: function (to, from, next) {
    // Indicate to the SubComponent that we are leaving the route
    this.$refs.checkoutform.updateCart(next);
    // Make sure to always call the next function, otherwise the hook will never be resolved
    // Ref: https://router.vuejs.org/en/advanced/navigation-guards.html
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.path;
      console.log(from);
    });
  },
};
</script>
