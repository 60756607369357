<template>
  <div class="bigContainer">
    <div class="ordersContainer">
      <h1>Order History</h1>
      <div
        v-for="(order, index) in orders"
        :key="index"
        class="order wholeborder"
      >
        <div class="flexContainer">
          <div class="orderId">
            <h2>Order Id:</h2>
          </div>
          <div class="deliveryDate">
            <h2>Estimated Delivery Date:</h2>
          </div>
          <div class="orderDate">
            <h2>Order Date:</h2>
          </div>
        </div>
        <div class="flexContainer border">
          <div class="orderId">
            <p>{{ order.referenceId }}</p>
          </div>
          <div class="deliveryDate">
            <p>{{ new Date(parseInt(order.deliveryDate)) }}</p>
          </div>
          <div class="orderDate">
            <p>{{ new Date(parseInt(order.dateTime)) }}</p>
          </div>
        </div>
        <div class="flexContainer">
          <div class="shippingAddress">
            <h2>Shipping</h2>
          </div>
          <div class="payment">
            <h2>Payment</h2>
          </div>
          <div class="orderTotal">
            <h2>Total</h2>
          </div>
        </div>
        <div class="flexContainer border">
          <div class="shippingAddress">
            <p v-if="order.name != null">{{ order.name }}</p>
            <p>
              {{ order.address1
              }}<span v-if="order.address2 != ''">, {{ order.address2 }}</span>
            </p>
            <p>{{ order.city }}, {{ order.state }} {{ order.zipcode }}</p>
            <p>{{ order.country }}</p>
            <p>{{ order.email }}</p>
            <p>{{ order.phone }}</p>
          </div>
          <div class="payment">
            <p>Payment ID: {{ order.paymentId }}</p>
            <p>Status: {{ order.paymentStatus }}</p>
            <p>
              Amount Approved: ${{
                (parseFloat(order.paymentAmountApproved) / 100).toFixed(2)
              }}
            </p>
          </div>
          <div class="orderTotal">
            <p>${{ order.total }}</p>
          </div>
        </div>
        <div class="productsContainer">
          <h2>Products</h2>
          <div class="flexContainer border">
            <div class="productName"><h3>Name</h3></div>
            <div class="productWeight"><h3>Weight</h3></div>
            <div class="productQuantity"><h3>Quantity</h3></div>
            <div class="productPrice"><h3>Price</h3></div>
          </div>
          <div
            v-for="(product, index) in order.productIds.split(',')"
            :key="index"
            class="product"
          >
            <div class="flexContainer border">
              <div class="productName">
                <p v-if="order.products[product] != null">{{ order.products[product].name }}</p>
              </div>
              <div class="productWeight">
                <p v-if="order.priceTiers[order.priceTierIds[index]] != null">
                  {{ order.priceTiers[order.priceTierIds[index]].weightGrams }}g
                </p>
              </div>
              <div class="productQuantity">
                <p>{{ order.quantities[index] }}</p>
              </div>
              <div class="productPrice">
                <p v-if="order.priceTiers[order.priceTierIds[index]] != null">
                  ${{
                    (
                      order.priceTiers[order.priceTierIds[index]].price *
                      order.quantities[index]
                    ).toFixed(2)
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminShippedOrders",
  props: {},
  data: function () {
    return {
      orders: [],
    };
  },
  methods: {
    getShippedOrders: function () {
      axios
        .get(this.$hostname + "/api/admin/orders/shipped")
        .then((response) => {
          this.orders = response.data;
          for (var i in this.orders) {
            this.orders[i].quantities = this.orders[i].quantities.split(",");
            this.orders[i].priceTierIds =
              this.orders[i].priceTierIds.split(",");
          }

          console.log(this.orders);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    shipOrder: function (index, orderId) {
      axios
        .post(this.$hostname + "/api/admin/order/ship", { id: orderId })
        .then((response) => {
          if (response.data == true) {
            this.orders.splice(index, 1);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted: function () {
    this.getShippedOrders();
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .wholeborder {
    border: 1px solid #eeeeee;
  }
  .border {
    border-bottom: 1px solid #eeeeee;
  }
  .flexContainer {
    display: flex;

    div {
      padding: 0 5%;
    }
    .orderId {
      flex: 1;
      text-align: left;
    }
    .orderDate {
      flex: 1;
      text-align: left;
    }
    .deliveryDate {
      flex: 2;
      text-align: left;
    }
    .shippingAddress {
      text-align: left;
      flex: 1;
      p {
        margin: 0;
      }
    }
    .payment {
      flex: 2;
      text-align: left;
      p {
        margin: 0;
      }
    }
    .orderTotal {
      flex: 1;
      text-align: left;
    }
    .productName {
      flex: 1;
      text-align: left;
    }
    .productWeight {
      flex: 1;
      text-align: left;
    }
    .productQuantity {
      flex: 1;
      text-align: left;
    }
    .productPrice {
      flex: 1;
      text-align: left;
    }
  }
  .ordersContainer {
    width: 90%;
    margin-left: 5%;
  }
  .order {
    padding: 5% 0;
  }
  .productsContainer {
    width: 90%;
    margin-left: 5%;
    h2 {
      text-align: left;
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
}

@media (max-width: 767px) {
  .wholeborder {
    border: 1px solid #eeeeee;
  }
  .border {
    border-bottom: 1px solid #eeeeee;
  }
  .flexContainer {
    display: flex;
    font-size: 0.4em !important;
    div {
      padding: 0 5%;
    }
    .orderId {
      flex: 1;
      text-align: left;
    }
    .orderDate {
      flex: 1;
      text-align: left;
    }
    .deliveryDate {
      flex: 2;
      text-align: left;
    }
    .shippingAddress {
      text-align: left;
      flex: 1;
      p {
        margin: 0;
      }
    }
    .payment {
      flex: 2;
      text-align: left;
      p {
        margin: 0;
      }
    }
    .orderTotal {
      flex: 1;
      text-align: left;
    }
    .productName {
      flex: 1;
      text-align: left;
    }
    .productWeight {
      flex: 1;
      text-align: left;
    }
    .productQuantity {
      flex: 1;
      text-align: left;
    }
    .productPrice {
      flex: 1;
      text-align: left;
    }
  }
  .ordersContainer {
    width: 90%;
    margin-left: 5%;
  }
  .order {
    padding: 5% 0;
  }
  .productsContainer {
    width: 90%;
    margin-left: 5%;
    h2 {
      text-align: left;
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }
  .loginBtn {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .loginBtn:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .loginBtn:active {
    position: relative;
    top: 1px;
  }
}
</style>
