<template>
  <div class="shop-container">
    <div
      class="card-background"
      v-for="(product, index) in products"
      :key="product.name"
    >
      <div class="card">
        <div v-show="loading[index]" class="lds-ring"><div></div><div></div><div></div><div></div></div>
        <div
          @click="
            $router.push({
              name: 'Product',
              params: {
                product: product.name.toLowerCase(),
                productId: parseInt(product.id),
              },
            })
          "
          class="imgContainer pointer"
        >
          <img
            class="productImg"
            alt="Hemp Flower"
            :src="getImgUrl(product.picture[0])"
          />
        </div>
        <div
          @click="
            $router.push({
              name: 'Product',
              params: {
                product: product.name.toLowerCase(),
                productId: parseInt(product.id),
              },
            })
          "
          class="productName pointer"
        >
          <h2>{{ product.name }}</h2>
        </div>
        <div class="productPrice">
          <p>${{ price(index, product.weightKey, product.qty) }}</p>
        </div>
        <div class="productWeight">
          Weight:<select v-model="product.weightKey">
            <option
              v-for="(weight, weightindex) in product.weights"
              :key="weight"
              :value="weightindex"
            >
              {{ weight }}g
            </option>
          </select>
          Qty:<input
            v-model="product.qty"
            type="number"
            id="quantity"
            name="quantity"
            min="1"
            :max="product.qtyGrams / product.weights[products[index].weightKey]"
          />
        </div>
        <div class="btnContainer">
          <button v-on:click="updateCart(index, product.qty)" class="atc">
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import axios from "axios";

export default {
  name: "ShopCards",
  props: {
    scroll: Number,
    subcategory: String,
  },
  data() {
    return {
      scroll1: 0,
      products: [],
      loading: [],
      productIndex: 0,
    };
  },
  methods: {
    getImgUrl: function (pic) {
      return this.$hostname + "/uploads/" + pic;
    },
    updateCart: function (productIndex, qty) {
      eventBus.$emit("atc", qty);
      //update cart in database
      axios.post(this.$hostname + "/api/cart/add", this.products[productIndex]);
      //change css to show user button was clicked
      this.productIndex = productIndex;
      this.loading[productIndex] = true;
      setTimeout(this.stopLoading, 500, productIndex);
    },
    stopLoading: function(productIndex){
      this.loading[productIndex] = false;
    },
    price: function (productIndex, weightKey, qty) {
      return (this.products[productIndex].prices[weightKey] * qty).toFixed(2);
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName("card")[0];
      console.log(el);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    getProducts() {
      axios
        .get(this.$hostname + "/api/products")
        .then((response) => {
          for (var i in response.data) {
            response.data[i].qty = "1";
            response.data[i].priceTierIds =
              response.data[i].priceTierIds.split(",");
            response.data[i].weights = response.data[i].weights.split(",");
            response.data[i].weightKey = 0;
            response.data[i].prices = response.data[i].prices.split(",");
            response.data[i].picture = response.data[i].picture.split(",");
          }

          this.products = response.data;
          this.loading = new Array(this.products.length).fill(false);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProductsWithSubcategory() {
      axios
        .post(this.$hostname + "/api/products/subcategory", {
          subcategory: this.$props.subcategory,
        })
        .then((response) => {
          for (var i in response.data) {
            response.data[i].qty = "1";
            response.data[i].priceTierIds =
              response.data[i].priceTierIds.split(",");
            response.data[i].weights = response.data[i].weights.split(",");
            response.data[i].weightKey = 0;
            response.data[i].prices = response.data[i].prices.split(",");
            response.data[i].picture = response.data[i].picture.split(",");
          }

          this.products = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {},
  mounted: function () {
    if (this.$props.subcategory == null) {
      this.getProducts();
    } else {
      this.getProductsWithSubcategory();
    }
  },
  updated: function () {
    if (this.scroll1 != this.$props.scroll) {
      this.scroll1 = this.$props.scroll;
      if (this.scroll1 == 1) {
        this.scrollToElement();
      }
    }

    if (this.subcategory1 != this.$props.subcategory) {
      this.subcategory1 = this.$props.subcategory;
      if (this.subcategory1 == null) {
        this.getProducts();
      } else {
        this.getProductsWithSubcategory();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.lds-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: rgba(255,255,255, 0.8);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f0f2f2 transparent transparent transparent;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin: auto;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 768px) {
  .shop-container {
    padding: 5%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .card-background {
    width: 27%;
    padding: 2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 2%;
  }
  .card {
    width: 100%;
    position: relative;
  }
  .imgContainer {
    width: 100%;
    .productImg {
      width: 50%;
    }
  }

  .btnContainer {
    padding: 5%;
  }
  .atc {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .atc:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .atc:active {
    position: relative;
    top: 1px;
  }
}

@media (max-width: 767px) {
  .shop-container {
    padding-top: 10%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .card-background {
    width: 90%;
    padding: 2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 2%;
  }
  .card {
    width: 100%;
  }
  .imgContainer {
    width: 100%;
    .productImg {
      width: 50%;
    }
  }

  .btnContainer {
    padding: 5%;
  }
  .atc {
    box-shadow: 0px 10px 14px -7px #3e7327;
    background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
    background-color: #417e3e;
    border-radius: 4px;
    border: 1px solid #4b8f29;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 28px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }
  .atc:hover {
    background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
    background-color: #346431;
  }
  .atc:active {
    position: relative;
    top: 1px;
  }
}
</style>
