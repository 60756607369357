<template>
    <div class="container">
        <div class="column">
            <h1>USCannabis.co</h1>
            <p>Quality wholesale hemp products you can trust.</p>
        </div>
        <div class="column">
            <h2>Contact Us</h2>
            <p>1132 4th Ave S Nashville TN 37210</p>
            <p>Call Us: (615) 618-3085</p>
            <p>orders@uscannabis.co</p>
        </div>
        <div class="column disclaimer">
            <p style="padding: 0 15%; font-size: 0.6em;">
                FDA DISCLAIMER: The statements made regarding these products have not been evaluated by the Food and Drug Administration. The efficacy of these products has not been confirmed by FDA-approved research. These products are not intended to diagnose, treat, cure or prevent any disease. All information presented here is not meant as a substitute for or alternative to information from health care practitioners. Please consult your health care professional about potential interactions or other possible complications before using any product. The Federal Food, Drug, and Cosmetic Act requires this notice. Do not consume if you are nursing or pregnant. Buyer assumes all responsibility.
            </p>
            <p style="padding: 0 15%; font-size: 0.6em;">
                Hemp products on this site contain no more than 0.3% THC in compliance with the 2018 Farm Bill.
            </p>
        </div>
        
    </div>
</template>
<script>
    export default {
        name: "Footer",
    };
</script>
<style lang="scss" scoped>
h1 {
    color: #417e3e;
}
h2 {
    color: #417e3e;
}
p {
    color: #2c3e50;
    font-size: 0.9em
}
@media (min-width: 768px) {
    .container {
        display: flex;
        border-top: 1px solid #efefef;
        padding: 3%;
    }
    .column {
        flex: 1;
    }
    .disclaimer {
        padding: 1%;
        text-align: left;
    }
}
@media (max-width: 767px) {
}
</style>