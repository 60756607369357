<template>
  <div class="">
    <div class="">
      <h1 class="title">Add a Product</h1>
      <div class="inputContainer">
        <input v-model="product.name" type="text" placeholder="Product Name" />
        <input
          v-model="product.qtyGrams"
          type="text"
          placeholder="Total Quantity (grams)"
        />
        <select v-model="product.priceTier">
          <option value="">Select a price tier</option>
          <option v-for="(p, i) in priceTiers" v-bind:key="i">
            {{ p.tier }}
          </option>
        </select>
        <p class="err">{{ tierNameErrorMsg }}</p>
      </div>
      <div class="inputContainer">
        <select v-model="product.subcategory">
          <option value="">Select a subcategory</option>
          <option v-for="(s, i) in subcategories" v-bind:key="i" :value="s.id">
            {{ s.subcategory }}
          </option>
        </select>
        <p class="err">{{ tierNameErrorMsg }}</p>
      </div>
      <div class="inputContainer">
        <textarea
          v-model="product.description"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
        <p class="err">{{ tierNameErrorMsg }}</p>
      </div>
      <div class="inputContainer">
        <input
          @change="previewFiles"
          type="file"
          accept="image/png, image/jpeg"
        />
        <input
          @change="previewFiles"
          type="file"
          accept="image/png, image/jpeg"
        />
        <input
          @change="previewFiles"
          type="file"
          accept="image/png, image/jpeg"
        />
        <input
          @change="previewFiles"
          type="file"
          accept="image/png, image/jpeg"
        />
      </div>
      <div class="btnContainer1">
        <button v-on:click="addProduct()" class="loginBtn">Add Product</button>
      </div>
    </div>
    <h1 class="title">Edit Products</h1>
    <div v-for="(p, i) in products" v-bind:key="i" class="inputContainer radio">
      <div class="email">
        <input v-model="p.name" type="text" />
        <input v-model="p.qtyGrams" type="text" />
        <select v-model="p.priceTier">
          <option>Select a price tier</option>
          <option v-for="(pt, j) in priceTiers" v-bind:key="j">
            {{ pt.tier }}
          </option>
        </select>
        <select v-model="p.subcategory">
          <option>Select a Sub-category</option>
          <option v-for="(sc, j) in subcategories" v-bind:key="j" :value="sc.id">
            {{ sc.subcategory }}
          </option>
        </select>
      </div>
      <div class="priv">
        <img
          v-for="(picture, j) in p.picture"
          v-bind:key="j"
          alt="Product Image"
          :src="this.$hostname + '/uploads/' + picture"
        />

        <input
          @change="previewFiles1(i, $event)"
          type="file"
          accept="image/png, image/jpeg"
        />
        <input
          @change="previewFiles1(i, $event)"
          type="file"
          accept="image/png, image/jpeg"
        />
        <input
          @change="previewFiles1(i, $event)"
          type="file"
          accept="image/png, image/jpeg"
        />
        <input
          @change="previewFiles1(i, $event)"
          type="file"
          accept="image/png, image/jpeg"
        />
      </div>

      <div class="btnContainer">
        <button v-on:click="updateProduct(i)" class="loginBtn">Update</button>
        <button v-on:click="deleteProduct(i)" class="redBtn">
          Delete Product
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminEditProducts",
  props: {},
  data: function () {
    return {
      products: [],
      product: {
        name: "",
        picture: "",
        priceTier: "",
        qtyGrams: "",
        files: [],
        description: "",
        subcategory: "",
      },
      priceTier: {
        tier: "",
        prices: new Array(8),
      },
      priceTiers: [],

      tierNameErrorMsg: "",

      subcategories: [],
    };
  },
  methods: {
    addPriceTiers: function () {
      axios
        .post(this.$hostname + "/api/admin/price-tier/add", this.priceTier)
        .then((response) => {
          if (response.data == true) {
            this.priceTiers.push(this.priceTier);
          } else {
            if (response.data.tier == false) {
              this.tierNameErrorMsg = "Please enter a valid tier name";
              return;
            }
            this.$router.go();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPriceTiers: function () {
      axios
        .get(this.$hostname + "/api/admin/price-tiers")
        .then((response) => {
          if (response.data) {
            for (var i in response.data) {
              var weights = response.data[i].weights.split(",");
              var pricesParse = response.data[i].prices.split(",");
              var prices = new Array(8);
              for (var j in weights) {
                switch (weights[j]) {
                  case "1":
                    prices[0] = pricesParse[j];
                    break;
                  case "3.5":
                    prices[1] = pricesParse[j];
                    break;
                  case "7":
                    prices[2] = pricesParse[j];
                    break;
                  case "14":
                    prices[3] = pricesParse[j];
                    break;
                  case "28":
                    prices[4] = pricesParse[j];
                    break;
                  case "112":
                    prices[5] = pricesParse[j];
                    break;
                  case "224":
                    prices[6] = pricesParse[j];
                    break;
                  case "448":
                    prices[7] = pricesParse[j];
                    break;
                }
              }
              this.priceTiers.push({
                tier: response.data[i].tier,
                prices: prices,
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSubcategories: function () {
      axios
        .get(this.$hostname + "/api/admin/subcategories")
        .then((response) => {
          this.subcategories = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProducts: function () {
      axios
        .get(this.$hostname + "/api/admin/products")
        .then((response) => {
          if (response.data) {
            for (var i in response.data) {
              var pictures = response.data[i].picture.split(",");
              response.data[i].picture = pictures;
              this.products.push(response.data[i]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateProduct: function (i) {
      const formData = new FormData();
      for (const j in this.products[i].files) {
        formData.append("files", this.products[i].files[j]);
      }
      for (const key in this.products[i]) {
        if (key != "files") {
          formData.append(key, this.products[i][key]);
        }
      }
      axios
        .post(this.$hostname + "/api/admin/product/update", formData)
        .then((response) => {
          console.log(response.data);
        });
    },
    deleteProduct: function (i) {
      axios
        .post(this.$hostname + "/api/admin/product/delete", {
          name: this.products[i].name,
          picture: this.products[i].picture,
        })
        .then((response) => {
          if (response.data) {
            this.products.splice(i, 1);
          }
        });
    },
    addProduct: function () {
      const formData = new FormData();
      for (const i in this.product.files) {
        formData.append("files", this.product.files[i]);
      }
      for (const key in this.product) {
        if (key != "files") {
          formData.append(key, this.product[key]);
        }
      }

      axios
        .post(this.$hostname + "/api/admin/products/add", formData)
        .then((response) => {
          if (response.data == true) {
            this.products.push(this.product);
          }
        });
    },
    previewFiles: function (event) {
      console.log(event.target.files[0]);
      this.product.files.push(event.target.files[0]);
    },
    previewFiles1: function (i, event) {
      if (this.products[i].files == null) {
        this.products[i].files = [];
      }
      console.log(event.target.files[0]);
      this.products[i].files.push(event.target.files[0]);
    },
  },
  mounted: function () {
    this.getPriceTiers();
    this.getSubcategories();
    this.getProducts();
  },
};
</script>
<style lang="scss" scoped>
.loginContainer {
  margin-top: 3%;

  width: 40%;
  margin-left: 30%;
  padding-top: 2%;
  padding-bottom: 7.5%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.title {
  color: #417e3e;
}
.inputContainer {
  padding-bottom: 1%;
  padding-top: 1%;
  width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  input {
    height: 25px;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}
.email {
  display: flex;
  flex: 2;
  align-items: center;
  flex-wrap: wrap;
  input {
    height: 25px;
  }
}
.priv {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  input {
    padding: 2%;
  }
  img {
    width: 50px;
  }
}

.radio {
  display: flex;
  flex-wrap: wrap;
  div {
    padding: 2%;
  }
  input {
    height: 1.2em;
  }
  label {
    font-size: 14px;
  }
}
.btnContainer {
  padding: 5%;
  align-items: center;
  display: flex;
  flex: 1;
}
.btnContainer1 {
  padding: 2%;
  align-items: center;
}
.loginBtn {
  box-shadow: 0px 10px 14px -7px #3e7327;
  background: linear-gradient(to bottom, #417e3e 5%, #346431 100%);
  background-color: #417e3e;
  border-radius: 4px;
  border: 1px solid #4b8f29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 28px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #5b8a3c;
}
.loginBtn:hover {
  background: linear-gradient(to bottom, #346431 5%, #417e3e 100%);
  background-color: #346431;
}
.loginBtn:active {
  position: relative;
  top: 1px;
}
.redBtn {
  box-shadow: 0px 10px 14px -7px #3e7327;
  background: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
  background-color: #b31217;
  border-radius: 4px;
  border: 1px solid #e52d27;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 28px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #e52d27;
}
.redBtn:hover {
  background-position: right center;
}
.redBtn:active {
  position: relative;
  top: 1px;
}

.err {
  color: #ff0033;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
}
</style>
